.b-form-preorder {
  width: 100%;
  height: auto;
  padding: 1rem;
  text-align: center;
  background: linear-gradient(45deg, #9940A3, #521FD9);

  @include flexy-breakpoint(large) {
    width: 600px;
    height: 500px;
    padding: 4rem 8rem;
    background: {
      image: url("../images/popup_bg.svg");
      position: 50% 50%;
      size: contain;
      repeat: no-repeat;
    }
  }

  &__icon {
    width: 68px;
    height: 59px;
    background: {
      image: url("../images/icon_popup.svg");
      position: 50% 50%;
      size: contain;
      repeat: no-repeat;
    }
    margin: 0 auto 1rem auto;
  }

  &__heading {
    font-size: 40px;
    font-family: 'Apercu Pro', sans-serif;
  }

  &__text {
    font-size: 16px;
    font-weight: 200;
    margin: 1rem 0;
  }
}
