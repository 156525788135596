.b-invest {
  text-align: center;
  margin-bottom: 100px;

  &__heading {
    position: relative;
    font-size: 80px;
    color: #75B82F;

    @include flexy-breakpoint(large) {
      font-size: 120px;
    }

    &::before {
      content: '$';
      color: #75B82F;
      position: absolute;
      left: -10px;
      top: 17px;
      font-size: 32px;

      @include flexy-breakpoint(large) {
        font-size: 40px;
        left: -20px;
        top: 30px;
      }
    }
  }

  &__text {
    color: #A298F1;
    font-size: 20px;
    margin-bottom: 1rem;
  }
}
