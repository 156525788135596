a {
  text-decoration: none;
}

.b-col__center {
  display: flex;
  justify-content: center;
}

.b-col__custom {
  background-color: #1D2049;
}