$flexy-grid-wrapper-max-width: 1280px;
$flexy-grid-gutter: 2rem;
$flexy-body-text-color: #fff;
$flexy-font: 'Apercu Pro', $flexy-default-font;
$flexy-heading-font: 'Apercu Pro', $flexy-default-font;
$flexy-theme-border-radius: 2px;
$flexy-colors: map-merge($flexy-default-colors, (
  default: #6666FF,
  secondary: #00E2A7,
  text: #A298F1,
  green: #00E2A7,
  button: #1b1b73,
  subscribe: #2C2C99,
  notification-background: #212529,
  placeholder: #a298f1
));
$flexy-theme-focus-border-size: 1px;
//$flexy-block-grid-padding: 34px;
