.b-features {
  position: relative;
  min-width: 100%;
  overflow: hidden;

  &::before {
    content: ' ';
    background: {
      image: url("../images/gradient/gradient2.svg");
      repeat: no-repeat;
      position: 50% 50%;
      size: contain;
    }
    width: 796px;
    height: 845px;
    position: absolute;
    top: 15%;
    right: 0;
    z-index: -2;

    @media all and (max-width: 767px) {
      top: 25%;
    }
  }

  &::after {
    content: ' ';
    background: {
      image: url("../images/gradient/gradient3.svg");
      repeat: no-repeat;
      position: 50% 50%;
      size: contain;
    }
    width: 940px;
    height: 862px;
    position: absolute;
    bottom: 17%;
    left: 0;
    z-index: -2;

    @media all and (max-width: 1024px) {
      bottom: 5%;
    }
  }

  &__block {
    margin-bottom: 2rem;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    margin-left: -1rem ;
    margin-right: -1rem ;
    padding-left: 1rem;
    padding-right: 1rem;

    @media all and (max-width: 767px) {
      flex-direction: column;
    }

    &_three {

      @media all and (max-width: 767px) {
        padding-top: 0 !important;
      }

      @media all and (max-width: 991px) {
        padding-top: 7rem;
      }
    }

  }
  &__head {
    display: block;
    width: 74px;
    height: 55px;

    &_one {
      background: {
        image: url("../images/features_1.svg");
        repeat: no-repeat;
        position: 50% 50%;
        size: contain;
      }
    }

    &_two {
      background: {
        image: url("../images/features_2.svg");
        repeat: no-repeat;
        position: 50% 50%;
        size: contain;
      }
    }

    &_three {
      background: {
        image: url("../images/features_3.svg");
        repeat: no-repeat;
        position: 50% 50%;
        size: contain;
      }
    }
  }
  
  &__heading {
    font-size: 40px;
    color: #fff;
  }
  
  &__text {
    @include antialias;
    color: flexy-color(text);
  }

  &__cart {
    padding-right: 2rem;
  }

  &__icon {
    display: block;
    margin: 1rem 0;

    &_server {
      background: {
        image: url("../images/icon_features/server.svg");
        position: 50% 50%;
        repeat: no-repeat;
        size: contain;
      }
      width: 71px;
      height: 62px;
    }

    &_rate {
      background: {
        image: url("../images/icon_features/rate.svg");
        position: 50% 50%;
        repeat: no-repeat;
        size: contain;
      }
      width: 63px;
      height: 62px;
    }

    &_bitcoin {
      background: {
        image: url("../images/icon_features/bitcoin.svg");
        position: 50% 50%;
        repeat: no-repeat;
        size: contain;
      }
      width: 65px;
      height: 64px;
    }

    &_web {
      background: {
        image: url("../images/icon_features/web.svg");
        position: 50% 50%;
        repeat: no-repeat;
        size: contain;
      }
      width: 67px;
      height: 58px;
    }

    &_circle {
      background: {
        image: url("../images/icon_features/circle.svg");
        position: 50% 50%;
        repeat: no-repeat;
        size: contain;
      }
      width: 61px;
      height: 68px;
    }

    &_kyc {
      background: {
        image: url("../images/icon_features/kyc.svg");
        position: 50% 50%;
        repeat: no-repeat;
        size: contain;
      }
      width: 61px;
      height: 68px;
    }

    &_bounty {
      background: {
        image: url("../images/icon_features/bounty.svg");
        position: 50% 50%;
        repeat: no-repeat;
        size: contain;
      }
      width: 61px;
      height: 68px;
    }

    &_statistic {
      background: {
        image: url("../images/icon_features/statistic.svg");
        position: 50% 50%;
        repeat: no-repeat;
        size: contain;
      }
      width: 61px;
      height: 68px;
    }
  }

  &__right {
    &_mrg {
      margin: 0;
      @include flexy-breakpoint(large) {
        margin-left: 3rem;
      }
    }
  }

  &__invisible {
    display: none;

    @include flexy-breakpoint(large) {
      display: block;
    }

    &_other {

      @media all and (max-width: 991px) {
        width: 380px;
      }

      @include flexy-breakpoint(large) {
        width: auto;
      }
    }
  }

  &__visible {
    display: block;

    @media all and (max-width: 767px) {
      display: none !important;
    }

    @include flexy-breakpoint(large) {
      display: none !important;
    }
  }

  &__mobile-block {
    position: relative;
  }

  &__mobile-img {
    position: absolute;
    top: -150px;

    &_relative {
      position: relative;
      left: -50px;

      @media all and (max-width: 991px) {
        left: -270px;
      }

      @media all and (max-width: 767px) {
        left: -270px;
        display: none;
      }
    }

    &__three {
      top: -225px;
    }
  }

  &__img {

    @include flexy-breakpoint(large) {
      width: 512px;
      height: 600px;
    }

    &_right {
      @include flexy-breakpoint(large) {
        margin-right: 10rem;
      }
    }

    &_left {
      @include flexy-breakpoint(large) {
        margin-left: 10rem;
      }
    }

    &_mobile {
      width: 100%;
    }
  }

  &__mobile {
    display: none;

    @media all and (max-width: 767px) {
      display: flex;
      justify-content: center;
      height: 300px;
    }
  }

}
