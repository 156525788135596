.b-footer {
  &__content {
    padding-top: 4rem;
    padding-bottom: 7rem;
    align-items: center;

    @include flexy-breakpoint(large) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__block {
    &_first {
      text-align: center;

      @include flexy-breakpoint(large) {
        text-align: left;
      }
    }

    &_last {
      text-align: center;

      @include flexy-breakpoint(large) {
        text-align: right;
      }
    }
  }

  &__link {
    @include antialias();
    color: flexy-color(text);

    &:not(:first-of-type) {
      margin-left: 1rem;
    }
  }

  &__buttons {
    display: none;
    position: fixed;
    bottom: 50px;
    right: 50px;

    @include flexy-breakpoint(large) {
      display: block;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    line-height: 2rem;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: linear-gradient(45deg, #AD6CAD, #7336FF);
    color: #fff;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
