.b-gradient {

  &__top {
    position: relative;
    min-width: 100%;
    overflow: hidden;

    &::before {
      content: ' ';
      background: {
        image: url("../images/gradient/gradient1.svg");
        repeat: no-repeat;
        position: 50% 50%;
        size: contain;
      }
      width: 650px;
      height: 570px;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -5;

      @include flexy-breakpoint(large) {
        width: 900px;
        height: 788px;
      }
    }

    &::after {
      content: ' ';
      background: {
        image: url("../images/layer-header.svg");
        repeat: no-repeat;
        position: 50% 50%;
        size: contain;
      }
      width: 400px;
      height: 400px;
      display: block;
      position: absolute;
      top: 0;
      left: -10%;
      z-index: -4;

      @include flexy-breakpoint(large) {
        width: 1366px;
        height: 790px;
      }
    }
  }

  &__down {
    background: linear-gradient(0deg, #2C3485, #1D2049);
  }
}
