.b-menu {
  display: flex;
  animation: fade 2s;

  @media all and (max-width: 767px) {
    display: none;
  }
  
  &__link {
    color: flexy-color(text);
    text-transform: uppercase;
    margin-right: 1rem;
    transition: all .5s ease;

    @include flexy-breakpoint(large) {
      margin-right: 2rem;
    }

    &:hover {
      color: #fff;
      transition: all .5s ease;
    }
  }

  &__home {
    color: flexy-color(text);
    text-transform: uppercase;
    margin-right: 1rem;
    transition: all .5s ease;

    @include flexy-breakpoint(large) {
      margin-right: 2rem;
    }

    &:hover {
      color: #fff;
      transition: all .5s ease;
    }
  }
}

@keyframes fade {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
