@font-face {
  font-family: 'Apercu Pro';
  src: url('../fonts/ApercuPro.eot');
  src: local('../fonts/ApercuPro'), local('../fonts/ApercuPro'),
  url('../fonts/ApercuPro.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ApercuPro.woff') format('woff'),
  url('../fonts/ApercuPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Apercu Pro';
  src: url('../fonts/ApercuPro-Bold.eot');
  src: local('../fonts/ApercuPro-Bold'), local('../fonts/ApercuPro-Bold'),
  url('../fonts/ApercuPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ApercuPro-Bold.woff') format('woff'),
  url('../fonts/ApercuPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Apercu Pro';
  src: url('../fonts/ApercuPro-Light.eot');
  src: local('ApercuPro-Light'), local('ApercuPro-Light'),
  url('../fonts/ApercuPro-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ApercuPro-Light.woff') format('woff'),
  url('../fonts/ApercuPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

