.b-hero-ico{
  padding-top: 40px;
  padding-bottom: 80px;

  @include flexy-breakpoint(large) {
    padding-top: 90px;
    padding-bottom: 180px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin: 40px 0;
    align-items: center;

    @include flexy-breakpoint(large) {
      align-items: flex-start;
      margin: 0;
    }

    &_last {
      margin: 0;
    }

    &::before {
      content: ' ';
      width: 1px;
      height: 200px;
      position: absolute;
      top: 0;
      left: -60px;
      background: #C0C2FF;
      opacity: 0.2;

      @media all and (max-width: 1170px) {
        display: none;
      }
    }
  }

  &__head {
    font-size: 20px;
    font-weight: 400;
  }

  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__icon {
    margin-right: 1rem;

    &_portfel {
      background: {
        image: url("../images/ico_page/portfel_icon.svg");
        position: 50% 50%;
        repeat: no-repeat;
        size: contain;
      }
      width: 32px;
      height: 28px;
    }

    &_home {
      background: {
        image: url("../images/ico_page/home_icon.svg");
        position: 50% 50%;
        repeat: no-repeat;
        size: contain;
      }
      width: 25px;
      height: 31px;
    }
  }
}
