.b-button {
  background: linear-gradient(90deg, rgba(9,172,204, 0.2), rgba(51,146,52,0.2) 50%);
  border-radius: 40px;
  box-sizing: border-box;
  display: inline-block;
  height: 80px;
  letter-spacing: 1px;
  padding: 8px;
  position: relative;
  text-transform: uppercase;
  width: 320px;
  margin: 1rem 0;
  animation: pulse 2s infinite cubic-bezier(0.20, 0, 0, 1);

  @media all and (max-width: 767px) {
    width: 100%;
  }

  @include flexy-breakpoint(large) {
    z-index: 2;
  }

  &:hover,
  &:focus,
  &:active {
    background: linear-gradient(90deg, rgba(9,172,204, 0.2), rgba(51,146,52,0.2) 50%);
  }

  &__back {
    background: linear-gradient(0.25turn, #09ACCC, #339234);
    color: #fff;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    transition: background .5s ease;
    //padding: 1rem 2rem;
    position: relative;

    &:hover {
      z-index: 4;
      &::before {
        opacity: 1;
        z-index: -2;
      }
    }

    &::before {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background: #75B82F;
      opacity: 0;
      transition: opacity 0.5s;
    }
  }

  &__text {
    z-index: 10;
    color: #fff;
    text-transform: uppercase;
  }

  @keyframes pulse {
      0% {
        box-shadow: 0 0 0 0 rgba(#09ACCC, 0.7);
      }

      100% {
        box-shadow: 0 0 0 6px rgba(232, 76, 61, 0);
      }
    }

  &_submit {
    background: linear-gradient(0.25turn, #09ACCC, #339234);
    height: 64px;

    &::after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      border-radius: inherit;
      width: 100%;
      height: 100%;
      background: #75B82F;
      opacity: 0;
      transition: opacity 0.5s;
      z-index: -4;
    }

    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      border-radius: inherit;
      width: 100%;
      height: 100%;
      background: linear-gradient(0.25turn, #09ACCC, #339234);
      opacity: 1;
      transition: opacity 0.5s;
      z-index: -5;
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }

  &__light {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    box-sizing: border-box;
    height: 70px;
    width: 100%;
    letter-spacing: 1px;
    font-weight: 400;
    padding: 8px;
    position: relative;
    text-transform: uppercase;
    border: 2px solid #A298F1;
    color: #fff;
    margin-top: 21px;
    background: transparent;
    animation: pulse 2s infinite cubic-bezier(0.20, 0, 0, 1);
    transition: all .5s ease;

    &:hover {
      background: rgba(192, 194, 255, 0.5);
    }
  }
}
