.b-input {
  border-bottom: 1px solid #a298f1;
  height: 56px;
  background: {
    color: transparent;
  }
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  color: #fff;

  &:hover:not(:focus) {
    box-shadow: none;
  }

  &:focus,
  &:active {
    box-shadow: none;
    border-bottom: 1px solid #fff;
  }

  &_error {
    border-color: lighten(flexy-color(error), 10%);
  }
}
