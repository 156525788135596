.b-offer {
  position: relative;
  display: flex;
  align-items: center;
  height: 1100px;
  min-width: 100%;
  overflow: hidden;

  @media all and (max-width: 767px) {
    height: 1500px;
  }
  
  &::before {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -2;
    background: {
      image: url("../images/offer_bg.svg");
      repeat: no-repeat;
      position: 50% 50%;
      size: contain;
    }
    width: 1100px;
    height: 1100px;

    @media all and (max-width: 767px) {
      width: 1500px;
      height: 1500px;
    }
  }

  &__block {
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
    flex-direction: row;
    position: relative;
    margin-top: 2rem;
    width: 670px;


    @media all and (max-width: 767px) {
      flex-direction: column;
      padding: 0;
      width: 100%;
    }

    &::before {
      display: block;
      content: ' ';
      width: 2px;
      height: 360px;
      background: rgba(255,255,255,0.2);
      position: absolute;
      top: 10%;
      left: 50%;

      @media all and (max-width: 767px) {
        display: none;
      }
    }
  }
  
  &__heading {
    color: #CEC9F5;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  &__text {
    @include antialias();
    color: flexy-color(text);
  }


  &__icon {
    width: 184px;
    height: 144px;
    margin-bottom: 1rem;

    &_left {
      background: {
        image: url("../images/offer_left.svg");
        position: 50% 50%;
        size: cover;
        repeat: no-repeat;
      }
    }

    &_right {
      background: {
        image: url("../images/offer_right.svg");
        position: 50% 50%;
        size: cover;
        repeat: no-repeat;
      }
    }
  }


  &__left {
    margin-right: 4rem;

    @media all and (max-width: 767px) {
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem;
    }
  }

  &__right {
    margin-left: 2rem;

    @media all and (max-width: 767px) {
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__right,
  &__left {
    width: 500px;

    @media all and (max-width: 767px) {
      width: auto;
    }
  }

  &__text {
    text-align: left;

    @media all and (max-width: 767px) {
      text-align: center;
    }
  }
}
