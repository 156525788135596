.b-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.4rem;
  color: #fff;

  &__img {
    background: {
      image: url("../images/dreamtech.svg");
      position: 50% 50%;
      size: contain;
      repeat: no-repeat;
    }
    display: block;
    width: 50px;
    height: 50px;
  }
}
