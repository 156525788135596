@charset "UTF-8";
.b-embed {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .b-embed::before {
    display: block;
    content: ''; }
  .b-embed_1by1::before {
    padding-top: 100%; }
  .b-embed_4by3::before {
    padding-top: 75%; }
  .b-embed_16by9::before {
    padding-top: 56.25%; }
  .b-embed_21by9::before {
    padding-top: 42.857143%; }
  .b-embed__item,
  .b-embed iframe,
  .b-embed object,
  .b-embed embed,
  .b-embed video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

* {
  box-sizing: border-box;
  outline: none; }

html {
  height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

table,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: top; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  background-color: #fff;
  font-style: normal;
  font-weight: normal;
  color: #fff;
  font-size: 16px;
  font-family: "Apercu Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 1.5; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

b,
strong {
  font-weight: bold; }

em,
i {
  font-style: italic; }

p {
  font-weight: normal; }

img {
  -ms-interpolation-mode: bicubic; }

label {
  display: inline-block;
  cursor: pointer; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  font-weight: normal;
  color: #000;
  font-family: "Apercu Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

a {
  color: #6666FF; }

::selection {
  background-color: #6666FF;
  color: #fff; }

input,
label,
select,
button,
textarea {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: normal;
  /* Browsers have different default form fonts */
  font-size: 16px;
  font-family: "Apercu Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

/* Box Sizing Reset
-----------------------------------------------*/
/* All of our custom controls should be what we expect them to be */
input,
textarea {
  box-sizing: content-box; }

/* These elements are usually rendered a certain way by the browser */
button,
input[type='reset'],
input[type='button'],
input[type='submit'],
input[type='checkbox'],
input[type='radio'],
select {
  box-sizing: border-box; }

button,
input,
textarea,
select {
  /* Remove the stupid outer glow in Webkit */
  outline: 0; }

/* Button Controls
-----------------------------------------------*/
input[type='checkbox'], input[type='radio'] {
  width: 13px;
  height: 13px; }

input[type='search'] {
  /* Make webkit render the search input like a normal text field */
  appearance: textfield;
  box-sizing: content-box; }

/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration {
  display: none; }

/* Buttons
-----------------------------------------------*/
button,
input[type='reset'],
input[type='button'],
input[type='submit'] {
  /* Fix IE7 display bug */
  overflow: visible;
  width: auto; }

/* IE8 and FF freak out if this rule is within another selector */
::-webkit-file-upload-button {
  padding: 0;
  border: 0;
  background: none; }

/* Textarea
-----------------------------------------------*/
textarea {
  /* Move the label to the top */
  vertical-align: top;
  /* Turn off scroll bars in IE unless needed */
  overflow: auto; }

/* Selects
-----------------------------------------------*/
select[multiple] {
  /* Move the label to the top */
  vertical-align: top; }

::-webkit-input-placeholder {
  font-size: 16px;
  color: #a298f1;
  font-family: "Apercu Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal; }

:-moz-placeholder {
  font-size: 16px;
  color: #a298f1;
  font-family: "Apercu Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal; }

::-moz-placeholder {
  font-size: 16px;
  color: #a298f1;
  font-family: "Apercu Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal; }

:-ms-input-placeholder {
  font-size: 16px;
  color: #a298f1;
  font-family: "Apercu Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal; }

/**
 * Spacing mixin to create uniform margin/padding
 */
/**
 * Row flex box parent.
 */
/**
 * Build the grid in two steps, to help minimize file size
 * Step 1, for each namespace, create the grid-base
 * Step 2, for each namespace, wrap the col width/offset measurements in their breakpoint media query
 */
/**
 * Generate a set of grid column classes using a namespace
 *
 * col-[namespace] for intelligent column division
 * col-[namespace]-[number] for a column that covers a specific number of columns (e.g. 1-12 by default)
 * off-[namespace]-[number] for pushing a col a specific number of columns (e.g. 1-11 by default)
 * off-[namespace]-reset for resetting a col's offset for that and larger namespaces
 */
.b-col_small {
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 1px;
  padding-left: 2rem;
  padding-right: 2rem;
  flex-grow: 1;
  flex-basis: 0; }
  .b-col_small_auto {
    -webkit-box-flex: 1;
    flex: 1 0 auto;
    max-width: 100%; }
  .b-col_small_1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }

.b-off_small_1 {
  margin-left: 8.33333%; }

.b-col_small {
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 1px;
  padding-left: 2rem;
  padding-right: 2rem;
  flex-grow: 1;
  flex-basis: 0; }
  .b-col_small_auto {
    -webkit-box-flex: 1;
    flex: 1 0 auto;
    max-width: 100%; }
  .b-col_small_2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }

.b-off_small_2 {
  margin-left: 16.66667%; }

.b-col_small {
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 1px;
  padding-left: 2rem;
  padding-right: 2rem;
  flex-grow: 1;
  flex-basis: 0; }
  .b-col_small_auto {
    -webkit-box-flex: 1;
    flex: 1 0 auto;
    max-width: 100%; }
  .b-col_small_3 {
    flex-basis: 25%;
    max-width: 25%; }

.b-off_small_3 {
  margin-left: 25%; }

.b-col_small {
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 1px;
  padding-left: 2rem;
  padding-right: 2rem;
  flex-grow: 1;
  flex-basis: 0; }
  .b-col_small_auto {
    -webkit-box-flex: 1;
    flex: 1 0 auto;
    max-width: 100%; }
  .b-col_small_4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }

.b-off_small_4 {
  margin-left: 33.33333%; }

.b-col_small {
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 1px;
  padding-left: 2rem;
  padding-right: 2rem;
  flex-grow: 1;
  flex-basis: 0; }
  .b-col_small_auto {
    -webkit-box-flex: 1;
    flex: 1 0 auto;
    max-width: 100%; }
  .b-col_small_5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }

.b-off_small_5 {
  margin-left: 41.66667%; }

.b-col_small {
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 1px;
  padding-left: 2rem;
  padding-right: 2rem;
  flex-grow: 1;
  flex-basis: 0; }
  .b-col_small_auto {
    -webkit-box-flex: 1;
    flex: 1 0 auto;
    max-width: 100%; }
  .b-col_small_6 {
    flex-basis: 50%;
    max-width: 50%; }

.b-off_small_6 {
  margin-left: 50%; }

.b-col_small {
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 1px;
  padding-left: 2rem;
  padding-right: 2rem;
  flex-grow: 1;
  flex-basis: 0; }
  .b-col_small_auto {
    -webkit-box-flex: 1;
    flex: 1 0 auto;
    max-width: 100%; }
  .b-col_small_7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }

.b-off_small_7 {
  margin-left: 58.33333%; }

.b-col_small {
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 1px;
  padding-left: 2rem;
  padding-right: 2rem;
  flex-grow: 1;
  flex-basis: 0; }
  .b-col_small_auto {
    -webkit-box-flex: 1;
    flex: 1 0 auto;
    max-width: 100%; }
  .b-col_small_8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }

.b-off_small_8 {
  margin-left: 66.66667%; }

.b-col_small {
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 1px;
  padding-left: 2rem;
  padding-right: 2rem;
  flex-grow: 1;
  flex-basis: 0; }
  .b-col_small_auto {
    -webkit-box-flex: 1;
    flex: 1 0 auto;
    max-width: 100%; }
  .b-col_small_9 {
    flex-basis: 75%;
    max-width: 75%; }

.b-off_small_9 {
  margin-left: 75%; }

.b-col_small {
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 1px;
  padding-left: 2rem;
  padding-right: 2rem;
  flex-grow: 1;
  flex-basis: 0; }
  .b-col_small_auto {
    -webkit-box-flex: 1;
    flex: 1 0 auto;
    max-width: 100%; }
  .b-col_small_10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }

.b-off_small_10 {
  margin-left: 83.33333%; }

.b-col_small {
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 1px;
  padding-left: 2rem;
  padding-right: 2rem;
  flex-grow: 1;
  flex-basis: 0; }
  .b-col_small_auto {
    -webkit-box-flex: 1;
    flex: 1 0 auto;
    max-width: 100%; }
  .b-col_small_11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }

.b-off_small_11 {
  margin-left: 91.66667%; }

.b-col_small {
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 1px;
  padding-left: 2rem;
  padding-right: 2rem;
  flex-grow: 1;
  flex-basis: 0; }
  .b-col_small_auto {
    -webkit-box-flex: 1;
    flex: 1 0 auto;
    max-width: 100%; }
  .b-col_small_12 {
    flex-basis: 100%;
    max-width: 100%; }

.b-off_small_reset {
  margin-left: 0; }

@media only screen and (min-width: 769px) {
  .b-col_medium {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_medium_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_medium_1 {
      flex-basis: 8.33333%;
      max-width: 8.33333%; }
  .b-off_medium_1 {
    margin-left: 8.33333%; }
  .b-col_medium {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_medium_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_medium_2 {
      flex-basis: 16.66667%;
      max-width: 16.66667%; }
  .b-off_medium_2 {
    margin-left: 16.66667%; }
  .b-col_medium {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_medium_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_medium_3 {
      flex-basis: 25%;
      max-width: 25%; }
  .b-off_medium_3 {
    margin-left: 25%; }
  .b-col_medium {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_medium_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_medium_4 {
      flex-basis: 33.33333%;
      max-width: 33.33333%; }
  .b-off_medium_4 {
    margin-left: 33.33333%; }
  .b-col_medium {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_medium_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_medium_5 {
      flex-basis: 41.66667%;
      max-width: 41.66667%; }
  .b-off_medium_5 {
    margin-left: 41.66667%; }
  .b-col_medium {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_medium_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_medium_6 {
      flex-basis: 50%;
      max-width: 50%; }
  .b-off_medium_6 {
    margin-left: 50%; }
  .b-col_medium {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_medium_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_medium_7 {
      flex-basis: 58.33333%;
      max-width: 58.33333%; }
  .b-off_medium_7 {
    margin-left: 58.33333%; }
  .b-col_medium {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_medium_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_medium_8 {
      flex-basis: 66.66667%;
      max-width: 66.66667%; }
  .b-off_medium_8 {
    margin-left: 66.66667%; }
  .b-col_medium {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_medium_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_medium_9 {
      flex-basis: 75%;
      max-width: 75%; }
  .b-off_medium_9 {
    margin-left: 75%; }
  .b-col_medium {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_medium_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_medium_10 {
      flex-basis: 83.33333%;
      max-width: 83.33333%; }
  .b-off_medium_10 {
    margin-left: 83.33333%; }
  .b-col_medium {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_medium_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_medium_11 {
      flex-basis: 91.66667%;
      max-width: 91.66667%; }
  .b-off_medium_11 {
    margin-left: 91.66667%; }
  .b-col_medium {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_medium_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_medium_12 {
      flex-basis: 100%;
      max-width: 100%; }
  .b-off_medium_reset {
    margin-left: 0; } }

@media only screen and (min-width: 992px) {
  .b-col_large {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_large_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_large_1 {
      flex-basis: 8.33333%;
      max-width: 8.33333%; }
  .b-off_large_1 {
    margin-left: 8.33333%; }
  .b-col_large {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_large_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_large_2 {
      flex-basis: 16.66667%;
      max-width: 16.66667%; }
  .b-off_large_2 {
    margin-left: 16.66667%; }
  .b-col_large {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_large_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_large_3 {
      flex-basis: 25%;
      max-width: 25%; }
  .b-off_large_3 {
    margin-left: 25%; }
  .b-col_large {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_large_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_large_4 {
      flex-basis: 33.33333%;
      max-width: 33.33333%; }
  .b-off_large_4 {
    margin-left: 33.33333%; }
  .b-col_large {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_large_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_large_5 {
      flex-basis: 41.66667%;
      max-width: 41.66667%; }
  .b-off_large_5 {
    margin-left: 41.66667%; }
  .b-col_large {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_large_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_large_6 {
      flex-basis: 50%;
      max-width: 50%; }
  .b-off_large_6 {
    margin-left: 50%; }
  .b-col_large {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_large_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_large_7 {
      flex-basis: 58.33333%;
      max-width: 58.33333%; }
  .b-off_large_7 {
    margin-left: 58.33333%; }
  .b-col_large {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_large_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_large_8 {
      flex-basis: 66.66667%;
      max-width: 66.66667%; }
  .b-off_large_8 {
    margin-left: 66.66667%; }
  .b-col_large {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_large_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_large_9 {
      flex-basis: 75%;
      max-width: 75%; }
  .b-off_large_9 {
    margin-left: 75%; }
  .b-col_large {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_large_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_large_10 {
      flex-basis: 83.33333%;
      max-width: 83.33333%; }
  .b-off_large_10 {
    margin-left: 83.33333%; }
  .b-col_large {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_large_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_large_11 {
      flex-basis: 91.66667%;
      max-width: 91.66667%; }
  .b-off_large_11 {
    margin-left: 91.66667%; }
  .b-col_large {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_large_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_large_12 {
      flex-basis: 100%;
      max-width: 100%; }
  .b-off_large_reset {
    margin-left: 0; } }

@media only screen and (min-width: 1200px) {
  .b-col_xlarge {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_xlarge_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_xlarge_1 {
      flex-basis: 8.33333%;
      max-width: 8.33333%; }
  .b-off_xlarge_1 {
    margin-left: 8.33333%; }
  .b-col_xlarge {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_xlarge_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_xlarge_2 {
      flex-basis: 16.66667%;
      max-width: 16.66667%; }
  .b-off_xlarge_2 {
    margin-left: 16.66667%; }
  .b-col_xlarge {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_xlarge_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_xlarge_3 {
      flex-basis: 25%;
      max-width: 25%; }
  .b-off_xlarge_3 {
    margin-left: 25%; }
  .b-col_xlarge {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_xlarge_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_xlarge_4 {
      flex-basis: 33.33333%;
      max-width: 33.33333%; }
  .b-off_xlarge_4 {
    margin-left: 33.33333%; }
  .b-col_xlarge {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_xlarge_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_xlarge_5 {
      flex-basis: 41.66667%;
      max-width: 41.66667%; }
  .b-off_xlarge_5 {
    margin-left: 41.66667%; }
  .b-col_xlarge {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_xlarge_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_xlarge_6 {
      flex-basis: 50%;
      max-width: 50%; }
  .b-off_xlarge_6 {
    margin-left: 50%; }
  .b-col_xlarge {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_xlarge_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_xlarge_7 {
      flex-basis: 58.33333%;
      max-width: 58.33333%; }
  .b-off_xlarge_7 {
    margin-left: 58.33333%; }
  .b-col_xlarge {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_xlarge_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_xlarge_8 {
      flex-basis: 66.66667%;
      max-width: 66.66667%; }
  .b-off_xlarge_8 {
    margin-left: 66.66667%; }
  .b-col_xlarge {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_xlarge_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_xlarge_9 {
      flex-basis: 75%;
      max-width: 75%; }
  .b-off_xlarge_9 {
    margin-left: 75%; }
  .b-col_xlarge {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_xlarge_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_xlarge_10 {
      flex-basis: 83.33333%;
      max-width: 83.33333%; }
  .b-off_xlarge_10 {
    margin-left: 83.33333%; }
  .b-col_xlarge {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_xlarge_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_xlarge_11 {
      flex-basis: 91.66667%;
      max-width: 91.66667%; }
  .b-off_xlarge_11 {
    margin-left: 91.66667%; }
  .b-col_xlarge {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_xlarge_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_xlarge_12 {
      flex-basis: 100%;
      max-width: 100%; }
  .b-off_xlarge_reset {
    margin-left: 0; } }

@media only screen and (min-width: 1600px) {
  .b-col_xxlarge {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_xxlarge_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_xxlarge_1 {
      flex-basis: 8.33333%;
      max-width: 8.33333%; }
  .b-off_xxlarge_1 {
    margin-left: 8.33333%; }
  .b-col_xxlarge {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_xxlarge_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_xxlarge_2 {
      flex-basis: 16.66667%;
      max-width: 16.66667%; }
  .b-off_xxlarge_2 {
    margin-left: 16.66667%; }
  .b-col_xxlarge {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_xxlarge_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_xxlarge_3 {
      flex-basis: 25%;
      max-width: 25%; }
  .b-off_xxlarge_3 {
    margin-left: 25%; }
  .b-col_xxlarge {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_xxlarge_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_xxlarge_4 {
      flex-basis: 33.33333%;
      max-width: 33.33333%; }
  .b-off_xxlarge_4 {
    margin-left: 33.33333%; }
  .b-col_xxlarge {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_xxlarge_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_xxlarge_5 {
      flex-basis: 41.66667%;
      max-width: 41.66667%; }
  .b-off_xxlarge_5 {
    margin-left: 41.66667%; }
  .b-col_xxlarge {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_xxlarge_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_xxlarge_6 {
      flex-basis: 50%;
      max-width: 50%; }
  .b-off_xxlarge_6 {
    margin-left: 50%; }
  .b-col_xxlarge {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_xxlarge_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_xxlarge_7 {
      flex-basis: 58.33333%;
      max-width: 58.33333%; }
  .b-off_xxlarge_7 {
    margin-left: 58.33333%; }
  .b-col_xxlarge {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_xxlarge_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_xxlarge_8 {
      flex-basis: 66.66667%;
      max-width: 66.66667%; }
  .b-off_xxlarge_8 {
    margin-left: 66.66667%; }
  .b-col_xxlarge {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_xxlarge_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_xxlarge_9 {
      flex-basis: 75%;
      max-width: 75%; }
  .b-off_xxlarge_9 {
    margin-left: 75%; }
  .b-col_xxlarge {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_xxlarge_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_xxlarge_10 {
      flex-basis: 83.33333%;
      max-width: 83.33333%; }
  .b-off_xxlarge_10 {
    margin-left: 83.33333%; }
  .b-col_xxlarge {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_xxlarge_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_xxlarge_11 {
      flex-basis: 91.66667%;
      max-width: 91.66667%; }
  .b-off_xxlarge_11 {
    margin-left: 91.66667%; }
  .b-col_xxlarge {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    flex-basis: 0; }
    .b-col_xxlarge_auto {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      max-width: 100%; }
    .b-col_xxlarge_12 {
      flex-basis: 100%;
      max-width: 100%; }
  .b-off_xxlarge_reset {
    margin-left: 0; } }

.b-col {
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 1px;
  padding-left: 2rem;
  padding-right: 2rem; }
  .b-col_auto {
    -webkit-box-flex: 1;
    flex: 1 0 auto;
    max-width: 100%; }

.b-row {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -2rem;
  margin-right: -2rem; }
  .b-row_center {
    justify-content: center; }
  .b-row_space {
    justify-content: space-between; }

.b-wrapper {
  margin: 0 auto;
  max-width: 1280px;
  min-width: auto;
  box-sizing: border-box;
  padding-left: 2rem;
  padding-right: 2rem; }
  .b-wrapper_left {
    margin: 0; }

.b-block-grid {
  list-style: none;
  padding: 0;
  margin: 0 -1rem;
  display: flex;
  flex-wrap: wrap; }
  .b-block-grid > .b-block-grid__item {
    display: block;
    height: auto;
    margin: 0;
    box-sizing: border-box;
    padding: 0 1rem 2rem; }
  .b-block-grid_left {
    justify-content: flex-start; }
  .b-block-grid_center {
    justify-content: center; }
  .b-block-grid_space {
    justify-content: space-between; }
  .b-block-grid_right {
    justify-content: flex-end; }
  @media only screen and (min-width: 0) {
    .b-block-grid_small_1 > .b-block-grid__item {
      width: 100%; } }
  @media only screen and (min-width: 0) and (max-width: 768.9px) {
    .b-block-grid_small_1 > .b-block-grid__item_only {
      width: 100%; } }
  @media only screen and (min-width: 0) {
    .b-block-grid_small_2 > .b-block-grid__item {
      width: 50%; } }
  @media only screen and (min-width: 0) and (max-width: 768.9px) {
    .b-block-grid_small_2 > .b-block-grid__item_only {
      width: 50%; } }
  @media only screen and (min-width: 0) {
    .b-block-grid_small_3 > .b-block-grid__item {
      width: 33.33333%; } }
  @media only screen and (min-width: 0) and (max-width: 768.9px) {
    .b-block-grid_small_3 > .b-block-grid__item_only {
      width: 33.33333%; } }
  @media only screen and (min-width: 0) {
    .b-block-grid_small_4 > .b-block-grid__item {
      width: 25%; } }
  @media only screen and (min-width: 0) and (max-width: 768.9px) {
    .b-block-grid_small_4 > .b-block-grid__item_only {
      width: 25%; } }
  @media only screen and (min-width: 0) {
    .b-block-grid_small_5 > .b-block-grid__item {
      width: 20%; } }
  @media only screen and (min-width: 0) and (max-width: 768.9px) {
    .b-block-grid_small_5 > .b-block-grid__item_only {
      width: 20%; } }
  @media only screen and (min-width: 0) {
    .b-block-grid_small_6 > .b-block-grid__item {
      width: 16.66667%; } }
  @media only screen and (min-width: 0) and (max-width: 768.9px) {
    .b-block-grid_small_6 > .b-block-grid__item_only {
      width: 16.66667%; } }
  @media only screen and (min-width: 0) {
    .b-block-grid_small_7 > .b-block-grid__item {
      width: 14.28571%; } }
  @media only screen and (min-width: 0) and (max-width: 768.9px) {
    .b-block-grid_small_7 > .b-block-grid__item_only {
      width: 14.28571%; } }
  @media only screen and (min-width: 0) {
    .b-block-grid_small_8 > .b-block-grid__item {
      width: 12.5%; } }
  @media only screen and (min-width: 0) and (max-width: 768.9px) {
    .b-block-grid_small_8 > .b-block-grid__item_only {
      width: 12.5%; } }
  @media only screen and (min-width: 0) {
    .b-block-grid_small_9 > .b-block-grid__item {
      width: 11.11111%; } }
  @media only screen and (min-width: 0) and (max-width: 768.9px) {
    .b-block-grid_small_9 > .b-block-grid__item_only {
      width: 11.11111%; } }
  @media only screen and (min-width: 0) {
    .b-block-grid_small_10 > .b-block-grid__item {
      width: 10%; } }
  @media only screen and (min-width: 0) and (max-width: 768.9px) {
    .b-block-grid_small_10 > .b-block-grid__item_only {
      width: 10%; } }
  @media only screen and (min-width: 0) {
    .b-block-grid_small_11 > .b-block-grid__item {
      width: 9.09091%; } }
  @media only screen and (min-width: 0) and (max-width: 768.9px) {
    .b-block-grid_small_11 > .b-block-grid__item_only {
      width: 9.09091%; } }
  @media only screen and (min-width: 0) {
    .b-block-grid_small_12 > .b-block-grid__item {
      width: 8.33333%; } }
  @media only screen and (min-width: 0) and (max-width: 768.9px) {
    .b-block-grid_small_12 > .b-block-grid__item_only {
      width: 8.33333%; } }
  @media only screen and (min-width: 769px) {
    .b-block-grid_medium_1 > .b-block-grid__item {
      width: 100%; } }
  @media only screen and (min-width: 769px) and (max-width: 991.9px) {
    .b-block-grid_medium_1 > .b-block-grid__item_only {
      width: 100%; } }
  @media only screen and (min-width: 769px) {
    .b-block-grid_medium_2 > .b-block-grid__item {
      width: 50%; } }
  @media only screen and (min-width: 769px) and (max-width: 991.9px) {
    .b-block-grid_medium_2 > .b-block-grid__item_only {
      width: 50%; } }
  @media only screen and (min-width: 769px) {
    .b-block-grid_medium_3 > .b-block-grid__item {
      width: 33.33333%; } }
  @media only screen and (min-width: 769px) and (max-width: 991.9px) {
    .b-block-grid_medium_3 > .b-block-grid__item_only {
      width: 33.33333%; } }
  @media only screen and (min-width: 769px) {
    .b-block-grid_medium_4 > .b-block-grid__item {
      width: 25%; } }
  @media only screen and (min-width: 769px) and (max-width: 991.9px) {
    .b-block-grid_medium_4 > .b-block-grid__item_only {
      width: 25%; } }
  @media only screen and (min-width: 769px) {
    .b-block-grid_medium_5 > .b-block-grid__item {
      width: 20%; } }
  @media only screen and (min-width: 769px) and (max-width: 991.9px) {
    .b-block-grid_medium_5 > .b-block-grid__item_only {
      width: 20%; } }
  @media only screen and (min-width: 769px) {
    .b-block-grid_medium_6 > .b-block-grid__item {
      width: 16.66667%; } }
  @media only screen and (min-width: 769px) and (max-width: 991.9px) {
    .b-block-grid_medium_6 > .b-block-grid__item_only {
      width: 16.66667%; } }
  @media only screen and (min-width: 769px) {
    .b-block-grid_medium_7 > .b-block-grid__item {
      width: 14.28571%; } }
  @media only screen and (min-width: 769px) and (max-width: 991.9px) {
    .b-block-grid_medium_7 > .b-block-grid__item_only {
      width: 14.28571%; } }
  @media only screen and (min-width: 769px) {
    .b-block-grid_medium_8 > .b-block-grid__item {
      width: 12.5%; } }
  @media only screen and (min-width: 769px) and (max-width: 991.9px) {
    .b-block-grid_medium_8 > .b-block-grid__item_only {
      width: 12.5%; } }
  @media only screen and (min-width: 769px) {
    .b-block-grid_medium_9 > .b-block-grid__item {
      width: 11.11111%; } }
  @media only screen and (min-width: 769px) and (max-width: 991.9px) {
    .b-block-grid_medium_9 > .b-block-grid__item_only {
      width: 11.11111%; } }
  @media only screen and (min-width: 769px) {
    .b-block-grid_medium_10 > .b-block-grid__item {
      width: 10%; } }
  @media only screen and (min-width: 769px) and (max-width: 991.9px) {
    .b-block-grid_medium_10 > .b-block-grid__item_only {
      width: 10%; } }
  @media only screen and (min-width: 769px) {
    .b-block-grid_medium_11 > .b-block-grid__item {
      width: 9.09091%; } }
  @media only screen and (min-width: 769px) and (max-width: 991.9px) {
    .b-block-grid_medium_11 > .b-block-grid__item_only {
      width: 9.09091%; } }
  @media only screen and (min-width: 769px) {
    .b-block-grid_medium_12 > .b-block-grid__item {
      width: 8.33333%; } }
  @media only screen and (min-width: 769px) and (max-width: 991.9px) {
    .b-block-grid_medium_12 > .b-block-grid__item_only {
      width: 8.33333%; } }
  @media only screen and (min-width: 992px) {
    .b-block-grid_large_1 > .b-block-grid__item {
      width: 100%; } }
  @media only screen and (min-width: 992px) and (max-width: 1199.9px) {
    .b-block-grid_large_1 > .b-block-grid__item_only {
      width: 100%; } }
  @media only screen and (min-width: 992px) {
    .b-block-grid_large_2 > .b-block-grid__item {
      width: 50%; } }
  @media only screen and (min-width: 992px) and (max-width: 1199.9px) {
    .b-block-grid_large_2 > .b-block-grid__item_only {
      width: 50%; } }
  @media only screen and (min-width: 992px) {
    .b-block-grid_large_3 > .b-block-grid__item {
      width: 33.33333%; } }
  @media only screen and (min-width: 992px) and (max-width: 1199.9px) {
    .b-block-grid_large_3 > .b-block-grid__item_only {
      width: 33.33333%; } }
  @media only screen and (min-width: 992px) {
    .b-block-grid_large_4 > .b-block-grid__item {
      width: 25%; } }
  @media only screen and (min-width: 992px) and (max-width: 1199.9px) {
    .b-block-grid_large_4 > .b-block-grid__item_only {
      width: 25%; } }
  @media only screen and (min-width: 992px) {
    .b-block-grid_large_5 > .b-block-grid__item {
      width: 20%; } }
  @media only screen and (min-width: 992px) and (max-width: 1199.9px) {
    .b-block-grid_large_5 > .b-block-grid__item_only {
      width: 20%; } }
  @media only screen and (min-width: 992px) {
    .b-block-grid_large_6 > .b-block-grid__item {
      width: 16.66667%; } }
  @media only screen and (min-width: 992px) and (max-width: 1199.9px) {
    .b-block-grid_large_6 > .b-block-grid__item_only {
      width: 16.66667%; } }
  @media only screen and (min-width: 992px) {
    .b-block-grid_large_7 > .b-block-grid__item {
      width: 14.28571%; } }
  @media only screen and (min-width: 992px) and (max-width: 1199.9px) {
    .b-block-grid_large_7 > .b-block-grid__item_only {
      width: 14.28571%; } }
  @media only screen and (min-width: 992px) {
    .b-block-grid_large_8 > .b-block-grid__item {
      width: 12.5%; } }
  @media only screen and (min-width: 992px) and (max-width: 1199.9px) {
    .b-block-grid_large_8 > .b-block-grid__item_only {
      width: 12.5%; } }
  @media only screen and (min-width: 992px) {
    .b-block-grid_large_9 > .b-block-grid__item {
      width: 11.11111%; } }
  @media only screen and (min-width: 992px) and (max-width: 1199.9px) {
    .b-block-grid_large_9 > .b-block-grid__item_only {
      width: 11.11111%; } }
  @media only screen and (min-width: 992px) {
    .b-block-grid_large_10 > .b-block-grid__item {
      width: 10%; } }
  @media only screen and (min-width: 992px) and (max-width: 1199.9px) {
    .b-block-grid_large_10 > .b-block-grid__item_only {
      width: 10%; } }
  @media only screen and (min-width: 992px) {
    .b-block-grid_large_11 > .b-block-grid__item {
      width: 9.09091%; } }
  @media only screen and (min-width: 992px) and (max-width: 1199.9px) {
    .b-block-grid_large_11 > .b-block-grid__item_only {
      width: 9.09091%; } }
  @media only screen and (min-width: 992px) {
    .b-block-grid_large_12 > .b-block-grid__item {
      width: 8.33333%; } }
  @media only screen and (min-width: 992px) and (max-width: 1199.9px) {
    .b-block-grid_large_12 > .b-block-grid__item_only {
      width: 8.33333%; } }
  @media only screen and (min-width: 1200px) {
    .b-block-grid_xlarge_1 > .b-block-grid__item {
      width: 100%; } }
  @media only screen and (min-width: 1200px) and (max-width: 1599.9px) {
    .b-block-grid_xlarge_1 > .b-block-grid__item_only {
      width: 100%; } }
  @media only screen and (min-width: 1200px) {
    .b-block-grid_xlarge_2 > .b-block-grid__item {
      width: 50%; } }
  @media only screen and (min-width: 1200px) and (max-width: 1599.9px) {
    .b-block-grid_xlarge_2 > .b-block-grid__item_only {
      width: 50%; } }
  @media only screen and (min-width: 1200px) {
    .b-block-grid_xlarge_3 > .b-block-grid__item {
      width: 33.33333%; } }
  @media only screen and (min-width: 1200px) and (max-width: 1599.9px) {
    .b-block-grid_xlarge_3 > .b-block-grid__item_only {
      width: 33.33333%; } }
  @media only screen and (min-width: 1200px) {
    .b-block-grid_xlarge_4 > .b-block-grid__item {
      width: 25%; } }
  @media only screen and (min-width: 1200px) and (max-width: 1599.9px) {
    .b-block-grid_xlarge_4 > .b-block-grid__item_only {
      width: 25%; } }
  @media only screen and (min-width: 1200px) {
    .b-block-grid_xlarge_5 > .b-block-grid__item {
      width: 20%; } }
  @media only screen and (min-width: 1200px) and (max-width: 1599.9px) {
    .b-block-grid_xlarge_5 > .b-block-grid__item_only {
      width: 20%; } }
  @media only screen and (min-width: 1200px) {
    .b-block-grid_xlarge_6 > .b-block-grid__item {
      width: 16.66667%; } }
  @media only screen and (min-width: 1200px) and (max-width: 1599.9px) {
    .b-block-grid_xlarge_6 > .b-block-grid__item_only {
      width: 16.66667%; } }
  @media only screen and (min-width: 1200px) {
    .b-block-grid_xlarge_7 > .b-block-grid__item {
      width: 14.28571%; } }
  @media only screen and (min-width: 1200px) and (max-width: 1599.9px) {
    .b-block-grid_xlarge_7 > .b-block-grid__item_only {
      width: 14.28571%; } }
  @media only screen and (min-width: 1200px) {
    .b-block-grid_xlarge_8 > .b-block-grid__item {
      width: 12.5%; } }
  @media only screen and (min-width: 1200px) and (max-width: 1599.9px) {
    .b-block-grid_xlarge_8 > .b-block-grid__item_only {
      width: 12.5%; } }
  @media only screen and (min-width: 1200px) {
    .b-block-grid_xlarge_9 > .b-block-grid__item {
      width: 11.11111%; } }
  @media only screen and (min-width: 1200px) and (max-width: 1599.9px) {
    .b-block-grid_xlarge_9 > .b-block-grid__item_only {
      width: 11.11111%; } }
  @media only screen and (min-width: 1200px) {
    .b-block-grid_xlarge_10 > .b-block-grid__item {
      width: 10%; } }
  @media only screen and (min-width: 1200px) and (max-width: 1599.9px) {
    .b-block-grid_xlarge_10 > .b-block-grid__item_only {
      width: 10%; } }
  @media only screen and (min-width: 1200px) {
    .b-block-grid_xlarge_11 > .b-block-grid__item {
      width: 9.09091%; } }
  @media only screen and (min-width: 1200px) and (max-width: 1599.9px) {
    .b-block-grid_xlarge_11 > .b-block-grid__item_only {
      width: 9.09091%; } }
  @media only screen and (min-width: 1200px) {
    .b-block-grid_xlarge_12 > .b-block-grid__item {
      width: 8.33333%; } }
  @media only screen and (min-width: 1200px) and (max-width: 1599.9px) {
    .b-block-grid_xlarge_12 > .b-block-grid__item_only {
      width: 8.33333%; } }
  @media only screen and (min-width: 1600px) {
    .b-block-grid_xxlarge_1 > .b-block-grid__item {
      width: 100%; } }
  @media only screen and (min-width: 1600px) {
    .b-block-grid_xxlarge_1 > .b-block-grid__item_only {
      width: 100%; } }
  @media only screen and (min-width: 1600px) {
    .b-block-grid_xxlarge_2 > .b-block-grid__item {
      width: 50%; } }
  @media only screen and (min-width: 1600px) {
    .b-block-grid_xxlarge_2 > .b-block-grid__item_only {
      width: 50%; } }
  @media only screen and (min-width: 1600px) {
    .b-block-grid_xxlarge_3 > .b-block-grid__item {
      width: 33.33333%; } }
  @media only screen and (min-width: 1600px) {
    .b-block-grid_xxlarge_3 > .b-block-grid__item_only {
      width: 33.33333%; } }
  @media only screen and (min-width: 1600px) {
    .b-block-grid_xxlarge_4 > .b-block-grid__item {
      width: 25%; } }
  @media only screen and (min-width: 1600px) {
    .b-block-grid_xxlarge_4 > .b-block-grid__item_only {
      width: 25%; } }
  @media only screen and (min-width: 1600px) {
    .b-block-grid_xxlarge_5 > .b-block-grid__item {
      width: 20%; } }
  @media only screen and (min-width: 1600px) {
    .b-block-grid_xxlarge_5 > .b-block-grid__item_only {
      width: 20%; } }
  @media only screen and (min-width: 1600px) {
    .b-block-grid_xxlarge_6 > .b-block-grid__item {
      width: 16.66667%; } }
  @media only screen and (min-width: 1600px) {
    .b-block-grid_xxlarge_6 > .b-block-grid__item_only {
      width: 16.66667%; } }
  @media only screen and (min-width: 1600px) {
    .b-block-grid_xxlarge_7 > .b-block-grid__item {
      width: 14.28571%; } }
  @media only screen and (min-width: 1600px) {
    .b-block-grid_xxlarge_7 > .b-block-grid__item_only {
      width: 14.28571%; } }
  @media only screen and (min-width: 1600px) {
    .b-block-grid_xxlarge_8 > .b-block-grid__item {
      width: 12.5%; } }
  @media only screen and (min-width: 1600px) {
    .b-block-grid_xxlarge_8 > .b-block-grid__item_only {
      width: 12.5%; } }
  @media only screen and (min-width: 1600px) {
    .b-block-grid_xxlarge_9 > .b-block-grid__item {
      width: 11.11111%; } }
  @media only screen and (min-width: 1600px) {
    .b-block-grid_xxlarge_9 > .b-block-grid__item_only {
      width: 11.11111%; } }
  @media only screen and (min-width: 1600px) {
    .b-block-grid_xxlarge_10 > .b-block-grid__item {
      width: 10%; } }
  @media only screen and (min-width: 1600px) {
    .b-block-grid_xxlarge_10 > .b-block-grid__item_only {
      width: 10%; } }
  @media only screen and (min-width: 1600px) {
    .b-block-grid_xxlarge_11 > .b-block-grid__item {
      width: 9.09091%; } }
  @media only screen and (min-width: 1600px) {
    .b-block-grid_xxlarge_11 > .b-block-grid__item_only {
      width: 9.09091%; } }
  @media only screen and (min-width: 1600px) {
    .b-block-grid_xxlarge_12 > .b-block-grid__item {
      width: 8.33333%; } }
  @media only screen and (min-width: 1600px) {
    .b-block-grid_xxlarge_12 > .b-block-grid__item_only {
      width: 8.33333%; } }

.b-show_small_only,
.b-visible_small_only {
  display: none; }
  @media only screen and (max-width: 768px) and (min-width: 0) {
    .b-show_small_only,
    .b-visible_small_only {
      display: block; } }

.b-show_small_up,
.b-visible_small_up {
  display: none; }
  @media only screen and (min-width: 0) {
    .b-show_small_up,
    .b-visible_small_up {
      display: block; } }

.b-show_medium_only,
.b-visible_medium_only {
  display: none; }
  @media only screen and (max-width: 991px) and (min-width: 769px) {
    .b-show_medium_only,
    .b-visible_medium_only {
      display: block; } }

.b-show_medium_up,
.b-visible_medium_up {
  display: none; }
  @media only screen and (min-width: 769px) {
    .b-show_medium_up,
    .b-visible_medium_up {
      display: block; } }

.b-show_large_only,
.b-visible_large_only {
  display: none; }
  @media only screen and (max-width: 1199px) and (min-width: 992px) {
    .b-show_large_only,
    .b-visible_large_only {
      display: block; } }

.b-show_large_up,
.b-visible_large_up {
  display: none; }
  @media only screen and (min-width: 992px) {
    .b-show_large_up,
    .b-visible_large_up {
      display: block; } }

.b-show_xlarge_only,
.b-visible_xlarge_only {
  display: none; }
  @media only screen and (max-width: 1599px) and (min-width: 1200px) {
    .b-show_xlarge_only,
    .b-visible_xlarge_only {
      display: block; } }

.b-show_xlarge_up,
.b-visible_xlarge_up {
  display: none; }
  @media only screen and (min-width: 1200px) {
    .b-show_xlarge_up,
    .b-visible_xlarge_up {
      display: block; } }

.b-show_xxlarge_only,
.b-visible_xxlarge_only {
  display: none; }
  @media only screen and (min-width: 1600px) {
    .b-show_xxlarge_only,
    .b-visible_xxlarge_only {
      display: block; } }

.b-show_xxlarge_up,
.b-visible_xxlarge_up {
  display: none; }
  @media only screen and (min-width: 1600px) {
    .b-show_xxlarge_up,
    .b-visible_xxlarge_up {
      display: block; } }

.b-hide {
  display: block; }
  @media only screen and (max-width: 768px) and (min-width: 0) {
    .b-hide_small_only {
      display: none; } }
  @media only screen and (min-width: 0) {
    .b-hide_small_up {
      display: none; } }
  @media only screen and (max-width: 991px) and (min-width: 769px) {
    .b-hide_medium_only {
      display: none; } }
  @media only screen and (min-width: 769px) {
    .b-hide_medium_up {
      display: none; } }
  @media only screen and (max-width: 1199px) and (min-width: 992px) {
    .b-hide_large_only {
      display: none; } }
  @media only screen and (min-width: 992px) {
    .b-hide_large_up {
      display: none; } }
  @media only screen and (max-width: 1599px) and (min-width: 1200px) {
    .b-hide_xlarge_only {
      display: none; } }
  @media only screen and (min-width: 1200px) {
    .b-hide_xlarge_up {
      display: none; } }
  @media only screen and (min-width: 1600px) {
    .b-hide_xxlarge_only {
      display: none; } }
  @media only screen and (min-width: 1600px) {
    .b-hide_xxlarge_up {
      display: none; } }

.m-color_default {
  color: #6666FF; }

.m-color_disabled {
  color: #f0f2f7; }

.m-color_white {
  color: #fff; }

.m-color_success {
  color: #27ae60; }

.m-color_error {
  color: #e74c3c; }

.m-color_text {
  color: #A298F1; }

.m-color_heading {
  color: #000; }

.m-color_placeholder {
  color: #a298f1; }

.m-color_divider {
  color: rgba(34, 36, 38, 0.15); }

.m-color_secondary {
  color: #00E2A7; }

.m-color_green {
  color: #00E2A7; }

.m-color_button {
  color: #1b1b73; }

.m-color_subscribe {
  color: #2C2C99; }

.m-color_notification-background {
  color: #212529; }

.m-bg_default {
  background-color: #6666FF; }

.m-bg_disabled {
  background-color: #f0f2f7; }

.m-bg_white {
  background-color: #fff; }

.m-bg_success {
  background-color: #27ae60; }

.m-bg_error {
  background-color: #e74c3c; }

.m-bg_text {
  background-color: #A298F1; }

.m-bg_heading {
  background-color: #000; }

.m-bg_placeholder {
  background-color: #a298f1; }

.m-bg_divider {
  background-color: rgba(34, 36, 38, 0.15); }

.m-bg_secondary {
  background-color: #00E2A7; }

.m-bg_green {
  background-color: #00E2A7; }

.m-bg_button {
  background-color: #1b1b73; }

.m-bg_subscribe {
  background-color: #2C2C99; }

.m-bg_notification-background {
  background-color: #212529; }

.m-align_center {
  text-align: center; }

.m-align_left {
  text-align: left; }

.m-align_right {
  text-align: right; }

.m-size_xxlarge {
  font-size: 2.5rem; }

.m-size_xlarge {
  font-size: 2rem; }

.m-size_large {
  font-size: 1.75rem; }

.m-size_medium {
  font-size: 1.5rem; }

.m-size_small {
  font-size: 1.25rem; }

.m-size_xsmall {
  font-size: 1rem; }

blockquote,
.b-blockquote {
  padding: 1rem;
  color: #a298f1;
  border-width: 1px 1px 1px 4px;
  border-style: solid;
  border-color: rgba(34, 36, 38, 0.15); }
  blockquote p:last-of-type,
  .b-blockquote p:last-of-type {
    margin-bottom: 0 !important; }

.b-typeset {
  font-size: 16px;
  line-height: 1.5; }
  .b-typeset h1, .b-typeset h1.b-heading {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 24px; }
    .b-typeset h1_no-offset, .b-typeset h1.b-heading_no-offset {
      margin: 0; }
  .b-typeset h2, .b-typeset h2.b-heading {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 24px; }
    .b-typeset h2_no-offset, .b-typeset h2.b-heading_no-offset {
      margin: 0; }
  .b-typeset h3, .b-typeset h3.b-heading {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 24px; }
    .b-typeset h3_no-offset, .b-typeset h3.b-heading_no-offset {
      margin: 0; }
  .b-typeset h4, .b-typeset h4.b-heading {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 24px; }
    .b-typeset h4_no-offset, .b-typeset h4.b-heading_no-offset {
      margin: 0; }
  .b-typeset h5, .b-typeset h5.b-heading {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 24px; }
    .b-typeset h5_no-offset, .b-typeset h5.b-heading_no-offset {
      margin: 0; }
  .b-typeset h6, .b-typeset h6.b-heading {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px; }
    .b-typeset h6_no-offset, .b-typeset h6.b-heading_no-offset {
      margin: 0; }
  .b-typeset p,
  .b-typeset ol,
  .b-typeset ul,
  .b-typeset pre {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 24px; }
  
  .b-typeset ul,
  .b-typeset ol {
    margin-left: 18px; }

.b-avatar {
  display: inline-block;
  position: relative;
  width: 3em;
  height: 3em;
  margin: 0;
  border-radius: 30em; }
  .b-avatar_text::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: attr(data-avatar);
    text-transform: uppercase; }
  .b-avatar_text {
    background-color: #2c3e50;
    color: #fff; }
  .b-avatar_text.b-avatar_primary {
    color: #fff; }
  .b-avatar_text.b-avatar_error {
    background-color: #e74c3c;
    color: #fff; }
  .b-avatar_text.b-avatar_success {
    background-color: #27ae60;
    color: #fff; }

.b-badge {
  border-radius: 0.28571rem;
  font-size: 0.9rem;
  padding: 0.2rem 0.4rem;
  display: inline-block;
  line-height: 1;
  color: #fff;
  box-sizing: border-box;
  position: relative;
  top: -0.5rem;
  background-color: #6666FF; }
  .b-badge_disabled {
    background-color: #f0f2f7; }
  .b-badge_white {
    background-color: #fff; }
  .b-badge_success {
    background-color: #27ae60; }
  .b-badge_error {
    background-color: #e74c3c; }
  .b-badge_text {
    background-color: #A298F1; }
  .b-badge_heading {
    background-color: #000; }
  .b-badge_placeholder {
    background-color: #a298f1; }
  .b-badge_divider {
    background-color: rgba(34, 36, 38, 0.15); }
  .b-badge_secondary {
    background-color: #00E2A7; }
  .b-badge_green {
    background-color: #00E2A7; }
  .b-badge_button {
    background-color: #1b1b73; }
  .b-badge_subscribe {
    background-color: #2C2C99; }
  .b-badge_notification-background {
    background-color: #212529; }

.b-breadcrumb {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .b-breadcrumb__item {
    display: inline-block;
    width: auto;
    padding: 0;
    color: #a298f1; }
  .b-breadcrumb__symbol {
    padding: 0 0.5em;
    color: #a298f1; }
  .b-breadcrumb__link {
    color: #6666FF;
    text-decoration: none; }
    .b-breadcrumb__link:hover {
      color: #3333ff; }

.b-button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 0;
  background-color: #96a8b2;
  color: #fff;
  display: inline-block;
  max-width: 100%;
  margin: 0;
  padding: 0 0.8rem;
  border-radius: 0.28571rem;
  outline: 0;
  font-family: inherit;
  text-align: center;
  vertical-align: middle;
  white-space: normal;
  background: none;
  text-decoration: none;
  cursor: pointer;
  appearance: none;
  user-select: none;
  box-sizing: border-box;
  flex-shrink: 0;
  min-width: 32px;
  line-height: 2;
  font-size: 1rem;
  color: #fff;
  background-color: #6666FF; }
  .b-button_xxlarge {
    font-size: 2.5rem; }
  .b-button_xlarge {
    font-size: 2rem; }
  .b-button_large {
    font-size: 1.75rem; }
  .b-button_medium {
    font-size: 1.5rem; }
  .b-button_small {
    font-size: 1.25rem; }
  .b-button_xsmall {
    font-size: 1rem; }
  .b-button_block {
    display: inline-block;
    width: 100% !important; }
  .b-button:hover, .b-button:focus {
    z-index: 2; }
  .b-button:hover, .b-button:focus {
    color: #fff;
    background-color: #9999ff; }
  .b-button:active {
    color: #fff;
    background-color: #3333ff; }
  .b-button_outline {
    color: #6666FF;
    box-shadow: 0 0 0 1px #6666FF inset;
    background-color: transparent; }
    .b-button_outline:hover, .b-button_outline:focus {
      color: #9999ff;
      box-shadow: 0 0 0 1px #9999ff inset;
      background-color: transparent; }
    .b-button_outline:active {
      color: #3333ff;
      box-shadow: 0 0 0 1px #3333ff inset;
      background-color: transparent; }
  .b-button_disabled {
    color: #A298F1;
    background-color: #f0f2f7; }
    .b-button_disabled:hover, .b-button_disabled:focus {
      color: #A298F1;
      background-color: white; }
    .b-button_disabled:active {
      color: #A298F1;
      background-color: #cfd5e5; }
    .b-button_disabled_outline {
      color: #f0f2f7;
      box-shadow: 0 0 0 1px #f0f2f7 inset;
      background-color: transparent; }
      .b-button_disabled_outline:hover, .b-button_disabled_outline:focus {
        color: white;
        box-shadow: 0 0 0 1px white inset;
        background-color: transparent; }
      .b-button_disabled_outline:active {
        color: #cfd5e5;
        box-shadow: 0 0 0 1px #cfd5e5 inset;
        background-color: transparent; }
  .b-button_white {
    color: #A298F1;
    background-color: #fff; }
    .b-button_white:hover, .b-button_white:focus {
      color: #A298F1;
      background-color: white; }
    .b-button_white:active {
      color: #A298F1;
      background-color: #e6e6e6; }
    .b-button_white_outline {
      color: #fff;
      box-shadow: 0 0 0 1px #fff inset;
      background-color: transparent; }
      .b-button_white_outline:hover, .b-button_white_outline:focus {
        color: white;
        box-shadow: 0 0 0 1px white inset;
        background-color: transparent; }
      .b-button_white_outline:active {
        color: #e6e6e6;
        box-shadow: 0 0 0 1px #e6e6e6 inset;
        background-color: transparent; }
  .b-button_success {
    color: #fff;
    background-color: #27ae60; }
    .b-button_success:hover, .b-button_success:focus {
      color: #fff;
      background-color: #36d278; }
    .b-button_success:active {
      color: #fff;
      background-color: #1e8449; }
    .b-button_success_outline {
      color: #27ae60;
      box-shadow: 0 0 0 1px #27ae60 inset;
      background-color: transparent; }
      .b-button_success_outline:hover, .b-button_success_outline:focus {
        color: #36d278;
        box-shadow: 0 0 0 1px #36d278 inset;
        background-color: transparent; }
      .b-button_success_outline:active {
        color: #1e8449;
        box-shadow: 0 0 0 1px #1e8449 inset;
        background-color: transparent; }
  .b-button_error {
    color: #fff;
    background-color: #e74c3c; }
    .b-button_error:hover, .b-button_error:focus {
      color: #fff;
      background-color: #ed7669; }
    .b-button_error:active {
      color: #fff;
      background-color: #d62c1a; }
    .b-button_error_outline {
      color: #e74c3c;
      box-shadow: 0 0 0 1px #e74c3c inset;
      background-color: transparent; }
      .b-button_error_outline:hover, .b-button_error_outline:focus {
        color: #ed7669;
        box-shadow: 0 0 0 1px #ed7669 inset;
        background-color: transparent; }
      .b-button_error_outline:active {
        color: #d62c1a;
        box-shadow: 0 0 0 1px #d62c1a inset;
        background-color: transparent; }
  .b-button_text {
    color: #fff;
    background-color: #A298F1; }
    .b-button_text:hover, .b-button_text:focus {
      color: #fff;
      background-color: #cbc5f7; }
    .b-button_text:active {
      color: #fff;
      background-color: #796beb; }
    .b-button_text_outline {
      color: #A298F1;
      box-shadow: 0 0 0 1px #A298F1 inset;
      background-color: transparent; }
      .b-button_text_outline:hover, .b-button_text_outline:focus {
        color: #cbc5f7;
        box-shadow: 0 0 0 1px #cbc5f7 inset;
        background-color: transparent; }
      .b-button_text_outline:active {
        color: #796beb;
        box-shadow: 0 0 0 1px #796beb inset;
        background-color: transparent; }
  .b-button_heading {
    color: #fff;
    background-color: #000; }
    .b-button_heading:hover, .b-button_heading:focus {
      color: #fff;
      background-color: #1a1a1a; }
    .b-button_heading:active {
      color: #fff;
      background-color: black; }
    .b-button_heading_outline {
      color: #000;
      box-shadow: 0 0 0 1px #000 inset;
      background-color: transparent; }
      .b-button_heading_outline:hover, .b-button_heading_outline:focus {
        color: #1a1a1a;
        box-shadow: 0 0 0 1px #1a1a1a inset;
        background-color: transparent; }
      .b-button_heading_outline:active {
        color: black;
        box-shadow: 0 0 0 1px black inset;
        background-color: transparent; }
  .b-button_placeholder {
    color: #fff;
    background-color: #a298f1; }
    .b-button_placeholder:hover, .b-button_placeholder:focus {
      color: #fff;
      background-color: #cbc5f7; }
    .b-button_placeholder:active {
      color: #fff;
      background-color: #796beb; }
    .b-button_placeholder_outline {
      color: #a298f1;
      box-shadow: 0 0 0 1px #a298f1 inset;
      background-color: transparent; }
      .b-button_placeholder_outline:hover, .b-button_placeholder_outline:focus {
        color: #cbc5f7;
        box-shadow: 0 0 0 1px #cbc5f7 inset;
        background-color: transparent; }
      .b-button_placeholder_outline:active {
        color: #796beb;
        box-shadow: 0 0 0 1px #796beb inset;
        background-color: transparent; }
  .b-button_divider {
    color: #fff;
    background-color: rgba(34, 36, 38, 0.15); }
    .b-button_divider:hover, .b-button_divider:focus {
      color: #fff;
      background-color: rgba(58, 62, 65, 0.15); }
    .b-button_divider:active {
      color: #fff;
      background-color: rgba(10, 11, 11, 0.15); }
    .b-button_divider_outline {
      color: rgba(34, 36, 38, 0.15);
      box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset;
      background-color: transparent; }
      .b-button_divider_outline:hover, .b-button_divider_outline:focus {
        color: rgba(58, 62, 65, 0.15);
        box-shadow: 0 0 0 1px rgba(58, 62, 65, 0.15) inset;
        background-color: transparent; }
      .b-button_divider_outline:active {
        color: rgba(10, 11, 11, 0.15);
        box-shadow: 0 0 0 1px rgba(10, 11, 11, 0.15) inset;
        background-color: transparent; }
  .b-button_secondary {
    color: #A298F1;
    background-color: #00E2A7; }
    .b-button_secondary:hover, .b-button_secondary:focus {
      color: #A298F1;
      background-color: #16ffc2; }
    .b-button_secondary:active {
      color: #A298F1;
      background-color: #00af81; }
    .b-button_secondary_outline {
      color: #00E2A7;
      box-shadow: 0 0 0 1px #00E2A7 inset;
      background-color: transparent; }
      .b-button_secondary_outline:hover, .b-button_secondary_outline:focus {
        color: #16ffc2;
        box-shadow: 0 0 0 1px #16ffc2 inset;
        background-color: transparent; }
      .b-button_secondary_outline:active {
        color: #00af81;
        box-shadow: 0 0 0 1px #00af81 inset;
        background-color: transparent; }
  .b-button_green {
    color: #A298F1;
    background-color: #00E2A7; }
    .b-button_green:hover, .b-button_green:focus {
      color: #A298F1;
      background-color: #16ffc2; }
    .b-button_green:active {
      color: #A298F1;
      background-color: #00af81; }
    .b-button_green_outline {
      color: #00E2A7;
      box-shadow: 0 0 0 1px #00E2A7 inset;
      background-color: transparent; }
      .b-button_green_outline:hover, .b-button_green_outline:focus {
        color: #16ffc2;
        box-shadow: 0 0 0 1px #16ffc2 inset;
        background-color: transparent; }
      .b-button_green_outline:active {
        color: #00af81;
        box-shadow: 0 0 0 1px #00af81 inset;
        background-color: transparent; }
  .b-button_button {
    color: #fff;
    background-color: #1b1b73; }
    .b-button_button:hover, .b-button_button:focus {
      color: #fff;
      background-color: #25259c; }
    .b-button_button:active {
      color: #fff;
      background-color: #11114a; }
    .b-button_button_outline {
      color: #1b1b73;
      box-shadow: 0 0 0 1px #1b1b73 inset;
      background-color: transparent; }
      .b-button_button_outline:hover, .b-button_button_outline:focus {
        color: #25259c;
        box-shadow: 0 0 0 1px #25259c inset;
        background-color: transparent; }
      .b-button_button_outline:active {
        color: #11114a;
        box-shadow: 0 0 0 1px #11114a inset;
        background-color: transparent; }
  .b-button_subscribe {
    color: #fff;
    background-color: #2C2C99; }
    .b-button_subscribe:hover, .b-button_subscribe:focus {
      color: #fff;
      background-color: #3737c1; }
    .b-button_subscribe:active {
      color: #fff;
      background-color: #212171; }
    .b-button_subscribe_outline {
      color: #2C2C99;
      box-shadow: 0 0 0 1px #2C2C99 inset;
      background-color: transparent; }
      .b-button_subscribe_outline:hover, .b-button_subscribe_outline:focus {
        color: #3737c1;
        box-shadow: 0 0 0 1px #3737c1 inset;
        background-color: transparent; }
      .b-button_subscribe_outline:active {
        color: #212171;
        box-shadow: 0 0 0 1px #212171 inset;
        background-color: transparent; }
  .b-button_notification-background {
    color: #fff;
    background-color: #212529; }
    .b-button_notification-background:hover, .b-button_notification-background:focus {
      color: #fff;
      background-color: #383f45; }
    .b-button_notification-background:active {
      color: #fff;
      background-color: #0a0c0d; }
    .b-button_notification-background_outline {
      color: #212529;
      box-shadow: 0 0 0 1px #212529 inset;
      background-color: transparent; }
      .b-button_notification-background_outline:hover, .b-button_notification-background_outline:focus {
        color: #383f45;
        box-shadow: 0 0 0 1px #383f45 inset;
        background-color: transparent; }
      .b-button_notification-background_outline:active {
        color: #0a0c0d;
        box-shadow: 0 0 0 1px #0a0c0d inset;
        background-color: transparent; }
  .b-button:not(.b-button_icon) > .icon {
    margin: 0 5px 0 0; }
  .b-button__icon {
    margin: 0; }

.b-card {
  border-radius: 0.28571rem; }

.b-card {
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5; }

.b-card {
  padding: 1em;
  background-color: #fff; }

.b-card:not(:last-of-type) {
  margin-bottom: 1em; }

.b-card__divider {
  margin: 1em -1em;
  border-top: 1px solid rgba(0, 0, 0, 0.05); }

.b-card__meta {
  color: rgba(0, 0, 0, 0.4); }

.b-card__footer {
  color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: space-between;
  align-items: center; }

.b-card__menu {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.b-card__item {
  min-width: 140px; }
  .b-card__item:not(:last-of-type) {
    margin-right: 1em; }

.b-card__name {
  font-weight: bold;
  color: #000; }

.b-divider, .b-typeset hr {
  *zoom: 1;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: rgba(34, 36, 38, 0.15);
  margin: 1em 0;
  line-height: 1;
  height: 0;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: rgba(0, 0, 0, 0.85);
  user-select: none;
  font-size: 1rem;
  margin: 12px 0; }
  .b-divider:before, .b-typeset hr:before, .b-divider:after, .b-typeset hr:after {
    display: table;
    content: '';
    line-height: 0; }
  .b-divider:after, .b-typeset hr:after {
    clear: both; }
  .b-divider_default {
    margin: 12px 0; }
  .b-divider_large {
    margin: 24px 0; }
  .b-divider_hero {
    margin: 36px 0; }

.b-errors {
  list-style: none; }
  .b-errors__error {
    color: #a00; }

.b-flash {
  margin-bottom: 0.5em;
  user-select: none;
  cursor: pointer;
  color: #fff;
  border-radius: 0.28571rem;
  box-sizing: border-box;
  padding: 0.5em 1em;
  font-size: 1rem; }
  .b-flash_success {
    background-color: #27ae60; }
  .b-flash_info {
    background-color: #3498db; }
  .b-flash_warning {
    background-color: #e67e22; }
  .b-flash_error {
    background-color: #c0392b; }

.b-form__row {
  position: relative; }
  .b-form__row:not(:last-child) {
    margin-bottom: 15px; }

.b-form__help {
  font-size: 0.9em;
  color: #aaa; }

.b-group {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end; }
  .b-group_space_between, .b-group_between {
    justify-content: space-between; }
  .b-group_space_around, .b-group_around {
    justify-content: space-around; }
  .b-group > .b-input-group:not(:last-child) {
    margin-right: 1em; }

.b-image, .b-typeset img, .b-figure__image {
  display: block;
  max-width: 100%;
  height: auto; }

kbd,
.b-kbd {
  padding: 0.05rem 0.2rem;
  font-size: 0.85rem;
  color: #A298F1;
  background-color: #f0f2f7;
  background-image: linear-gradient(180deg, white, transparent);
  background-position: 0 0;
  background-repeat: no-repeat;
  border: 1px solid rgba(223, 228, 238, 0.8);
  border-radius: 0.28571rem; }
  kbd_inverted,
  .b-kbd_inverted {
    color: #fff;
    background-color: #A298F1;
    background-image: linear-gradient(180deg, #cbc5f7, transparent);
    border: 1px solid rgba(142, 130, 238, 0.8); }

.b-figure__caption {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #a298f1; }

@font-face {
  font-family: 'flexy-checkbox';
  src: url(data:application/x-font-ttf;charset=utf-8;base64,AAEAAAALAIAAAwAwT1MvMg8SBD8AAAC8AAAAYGNtYXAYVtCJAAABHAAAAFRnYXNwAAAAEAAAAXAAAAAIZ2x5Zn4huwUAAAF4AAABYGhlYWQGPe1ZAAAC2AAAADZoaGVhB30DyAAAAxAAAAAkaG10eBBKAEUAAAM0AAAAHGxvY2EAmgESAAADUAAAABBtYXhwAAkALwAAA2AAAAAgbmFtZSC8IugAAAOAAAABknBvc3QAAwAAAAAFFAAAACAAAwMTAZAABQAAApkCzAAAAI8CmQLMAAAB6wAzAQkAAAAAAAAAAAAAAAAAAAABEAAAAAAAAAAAAAAAAAAAAABAAADoAgPA/8AAQAPAAEAAAAABAAAAAAAAAAAAAAAgAAAAAAADAAAAAwAAABwAAQADAAAAHAADAAEAAAAcAAQAOAAAAAoACAACAAIAAQAg6AL//f//AAAAAAAg6AD//f//AAH/4xgEAAMAAQAAAAAAAAAAAAAAAQAB//8ADwABAAAAAAAAAAAAAgAANzkBAAAAAAEAAAAAAAAAAAACAAA3OQEAAAAAAQAAAAAAAAAAAAIAADc5AQAAAAABAEUAUQO7AvgAGgAAARQHAQYjIicBJjU0PwE2MzIfAQE2MzIfARYVA7sQ/hQQFhcQ/uMQEE4QFxcQqAF2EBcXEE4QAnMWEP4UEBABHRAXFhBOEBCoAXcQEE4QFwAAAAABAAABbgMlAkkAFAAAARUUBwYjISInJj0BNDc2MyEyFxYVAyUQEBf9SRcQEBAQFwK3FxAQAhJtFxAQEBAXbRcQEBAQFwAAAAABAAAASQMlA24ALAAAARUUBwYrARUUBwYrASInJj0BIyInJj0BNDc2OwE1NDc2OwEyFxYdATMyFxYVAyUQEBfuEBAXbhYQEO4XEBAQEBfuEBAWbhcQEO4XEBACEm0XEBDuFxAQEBAX7hAQF20XEBDuFxAQEBAX7hAQFwAAAQAAAAIAAHRSzT9fDzz1AAsEAAAAAADRsdR3AAAAANGx1HcAAAAAA7sDbgAAAAgAAgAAAAAAAAABAAADwP/AAAAEAAAAAAADuwABAAAAAAAAAAAAAAAAAAAABwQAAAAAAAAAAAAAAAIAAAAEAABFAyUAAAMlAAAAAAAAAAoAFAAeAE4AcgCwAAEAAAAHAC0AAQAAAAAAAgAAAAAAAAAAAAAAAAAAAAAAAAAOAK4AAQAAAAAAAQAIAAAAAQAAAAAAAgAHAGkAAQAAAAAAAwAIADkAAQAAAAAABAAIAH4AAQAAAAAABQALABgAAQAAAAAABgAIAFEAAQAAAAAACgAaAJYAAwABBAkAAQAQAAgAAwABBAkAAgAOAHAAAwABBAkAAwAQAEEAAwABBAkABAAQAIYAAwABBAkABQAWACMAAwABBAkABgAQAFkAAwABBAkACgA0ALBDaGVja2JveABDAGgAZQBjAGsAYgBvAHhWZXJzaW9uIDIuMABWAGUAcgBzAGkAbwBuACAAMgAuADBDaGVja2JveABDAGgAZQBjAGsAYgBvAHhDaGVja2JveABDAGgAZQBjAGsAYgBvAHhSZWd1bGFyAFIAZQBnAHUAbABhAHJDaGVja2JveABDAGgAZQBjAGsAYgBvAHhGb250IGdlbmVyYXRlZCBieSBJY29Nb29uLgBGAG8AbgB0ACAAZwBlAG4AZQByAGEAdABlAGQAIABiAHkAIABJAGMAbwBNAG8AbwBuAC4AAAADAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA); }

.b-input {
  appearance: none;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0.5em;
  border: none;
  border-radius: 0.28571rem;
  outline: none;
  background-color: #fff;
  font-family: inherit;
  font-size: 1em;
  font-weight: 400;
  resize: vertical;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 0 0 1px #c8c8c8 inset;
  /** SELECT **************************/
  /** RANGE **************************/
  /** CHECKBOX, RADIO **************************/ }
  .b-input[type='search'] {
    box-sizing: border-box; }
  .b-input:hover:not(:focus) {
    box-shadow: 0 0 0 2px rgba(102, 102, 255, 0.5) inset; }
  .b-input:active, .b-input:focus {
    box-shadow: 0 0 0 2px #6666FF inset; }
  .b-input:hover, .b-input:focus {
    z-index: 2; }
  .b-input:not(:focus) {
    cursor: pointer; }
  .b-input_no-border-right {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .b-input_no-border-left {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .b-input_select:not([multiple]) {
    padding-right: 2em;
    background-image: url(data:image/png;base64,R0lGODlhDwAUAIABAAAAAP///yH5BAEAAAEALAAAAAAPABQAAAIXjI+py+0Po5wH2HsXzmw//lHiSJZmUAAAOw==);
    background-repeat: no-repeat;
    background-position: calc(100% - 0.5em) 50%; }
  .b-input_select::-ms-expand {
    display: none; }
  .b-input_range {
    width: 100%;
    padding: 0.5em 0;
    outline: 0;
    appearance: none; }
    .b-input_range, .b-input_range:hover, .b-input_range:active, .b-input_range:focus {
      box-shadow: none !important; }
    .b-input_range:active::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #6666FF inset, 0 1px 2px rgba(0, 0, 0, 0.6); }
    .b-input_range::-moz-focus-outer {
      border: 0; }
    .b-input_range::-webkit-slider-runnable-track {
      background-color: rgba(200, 200, 200, 0.4); }
    .b-input_range::-moz-range-track {
      background-color: rgba(200, 200, 200, 0.4); }
    .b-input_range::-ms-track {
      background-color: rgba(200, 200, 200, 0.4); }
    .b-input_range::-webkit-slider-runnable-track {
      border-radius: 30em;
      width: 100%;
      height: 10px;
      border: 0;
      box-shadow: none;
      cursor: pointer; }
    .b-input_range::-webkit-slider-thumb {
      border-radius: 30em;
      width: 20px;
      height: 20px;
      margin: -5px 0 0;
      border: 0;
      background-color: #fff;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
      cursor: pointer;
      appearance: none; }
    .b-input_range::-moz-range-track {
      width: 100%;
      height: 10px;
      border: 0;
      border-radius: 30em;
      box-shadow: none;
      cursor: pointer; }
    .b-input_range::-moz-range-thumb {
      border-radius: 30em;
      width: 20px;
      height: 20px;
      margin: -5px 0 0;
      border: 0;
      background-color: #fff;
      box-shadow: 0 1px 4px -1px #111;
      cursor: pointer; }
    .b-input_range::-ms-track {
      border-radius: 30em;
      width: 100%;
      height: 10px;
      border: 0;
      box-shadow: none;
      cursor: pointer;
      border-color: transparent;
      background-color: transparent;
      color: transparent; }
    .b-input_range::-ms-fill-lower, .b-input_range::-ms-fill-upper {
      border-radius: 30em;
      border: 0;
      background-color: #96a8b2;
      box-shadow: none; }
  .b-input_choice {
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    margin: 1px 0 0 0; }
    .b-input_choice[type='radio'], .b-input_choice[type='checkbox'] {
      width: 20.8px;
      height: 20.8px;
      line-height: 20.8px; }
      .b-input_choice[type='radio'] + .b-label, .b-input_choice[type='checkbox'] + .b-label {
        display: block;
        padding-left: 32px;
        position: relative; }
        .b-input_choice[type='radio'] + .b-label::before, .b-input_choice[type='radio'] + .b-label::after, .b-input_choice[type='checkbox'] + .b-label::before, .b-input_choice[type='checkbox'] + .b-label::after {
          margin: 1px 0 0 0;
          font-family: 'flexy-checkbox';
          display: block;
          box-sizing: border-box;
          position: absolute;
          left: 0;
          top: 0;
          font-size: 12px;
          width: 20.8px;
          height: 20.8px;
          line-height: 20.8px; }
        .b-input_choice[type='radio'] + .b-label::before, .b-input_choice[type='checkbox'] + .b-label::before {
          z-index: 2;
          font-weight: bold;
          color: #fff;
          text-align: center;
          content: '';
          transform: scale(0); }
        .b-input_choice[type='radio'] + .b-label::after, .b-input_choice[type='checkbox'] + .b-label::after {
          z-index: 1;
          content: ' ';
          border-width: 1px;
          border-style: solid;
          border-color: #c8c8c8;
          background-color: #fff; }
        .b-input_choice[type='radio'] + .b-label:hover::after, .b-input_choice[type='checkbox'] + .b-label:hover::after {
          border-width: 1px;
          border-style: solid;
          border-color: rgba(102, 102, 255, 0.5); }
      .b-input_choice[type='radio']:indeterminate + .b-label, .b-input_choice[type='checkbox']:indeterminate + .b-label {
        content: '\e801'; }
    .b-input_choice[type='radio'] + .b-label::before, .b-input_choice[type='radio'] + .b-label::after {
      border-radius: 100%; }
    .b-input_choice[type='checkbox'] + .b-label::before, .b-input_choice[type='checkbox'] + .b-label::after {
      border-radius: 0.28571rem; }
    .b-input_choice:checked[type='checkbox'] + .b-label::before, .b-input_choice:checked[type='radio'] + .b-label::before {
      animation: flexy-choice 0.25s;
      transform: scale(1);
      color: #6666FF;
      content: '\e800'; }
    .b-input_choice:checked[type='checkbox'] + .b-label::after, .b-input_choice:checked[type='radio'] + .b-label::after {
      border-width: 2px;
      border-style: solid;
      border-color: #6666FF; }
  .b-input_textarea {
    height: auto;
    min-height: 4.2em; }

@keyframes flexy-choice {
  0% {
    transform: scale(0); }
  50% {
    transform: scale(1.5); }
  to {
    transform: scale(1); } }

.b-input-group {
  display: flex;
  flex-wrap: nowrap; }
  .b-input-group_right {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: stretch;
    align-content: stretch; }
  .b-input-group > .b-input,
  .b-input-group > .b-button {
    border-radius: 0; }
    .b-input-group > .b-input:not(:first-child),
    .b-input-group > .b-button:not(:first-child) {
      margin-left: -1px; }
    .b-input-group > .b-input:first-child,
    .b-input-group > .b-button:first-child {
      border-bottom-left-radius: 0.28571rem;
      border-top-left-radius: 0.28571rem; }
    .b-input-group > .b-input:last-child,
    .b-input-group > .b-button:last-child {
      border-bottom-right-radius: 0.28571rem;
      border-top-right-radius: 0.28571rem; }

.b-label {
  color: #6a6a6a;
  display: block;
  cursor: pointer;
  font-family: inherit;
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 0.5em; }
  .b-label_checkbox {
    color: #A298F1; }
  .b-label__asterisk {
    color: #e74c3c;
    font-weight: bold; }

.b-empty__icon {
  text-align: center; }
  .b-empty__icon,
  .b-empty__icon svg {
    height: 64px;
    width: auto;
    stroke: #6a6a6a; }

.b-empty__title {
  color: #6a6a6a;
  font-size: 2rem;
  text-align: center; }

.b-empty__text {
  text-align: center;
  color: #6a6a6a; }

.b-pager {
  display: flex;
  margin: 1em 0;
  align-items: center; }
  .b-pager__list {
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex: 1 0 auto; }
  .b-pager__total {
    padding: 0 1em; }
  .b-pager__link {
    box-sizing: border-box;
    border-radius: 0.28571rem;
    text-align: center;
    display: block;
    padding: 0 0.2em;
    text-decoration: none;
    transition: color 0.2s ease; }
    .b-pager__link_disabled {
      cursor: not-allowed; }
  .b-pager__item {
    margin: 0 5px; }
    .b-pager__item_current .b-pager__link {
      cursor: not-allowed; }

.b-space {
  height: 1em; }
  .b-space_small {
    height: 0.5em; }
  .b-space_default {
    height: 1em; }
  .b-space_large {
    height: 2em; }
  .b-space_xlarge {
    height: 4em; }
  .b-space_xxlarge {
    height: 6em; }

.b-tab {
  display: block; }
  .b-tab__header {
    display: flex;
    justify-content: flex-start;
    text-align: center;
    box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.05); }
  .b-tab__tab {
    cursor: pointer;
    margin: 0;
    padding: 0.5em 1em;
    border-radius: 0.28571rem 0.28571rem 0 0;
    transition: all 0.25s ease;
    color: #A298F1;
    text-decoration: none; }
    .b-tab__tab:hover {
      color: #6666FF;
      box-shadow: inset 0 -2px 0 0 #6666FF; }
    .b-tab__tab_active {
      cursor: default;
      color: #6666FF;
      box-shadow: inset 0 -2px 0 0 #6666FF; }
      .b-tab__tab_active:hover {
        transform: none; }
    .b-tab__tab_disabled {
      cursor: not-allowed;
      color: #96a8b2; }
      .b-tab__tab_disabled:hover {
        box-shadow: none;
        color: #96a8b2; }
  .b-tab__content {
    display: none;
    padding: 1em 0; }
    .b-tab__content_padding {
      padding: 1em; }
    .b-tab__content_active {
      display: block; }

.b-table {
  width: 100%;
  margin: 1em 0;
  box-shadow: none;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
  border-collapse: collapse;
  border-spacing: 0; }
  .b-table:first-child {
    margin-top: 0; }
  .b-table:last-child {
    margin-bottom: 0; }
  .b-table__thead, .b-table__tfoot {
    box-shadow: none; }
  .b-table__td, .b-table__th {
    border: 1px solid #e8e8e8;
    padding: 4px 8px;
    vertical-align: inherit;
    cursor: auto;
    transition: background 0.1s ease, color 0.1s ease;
    text-transform: none;
    color: rgba(0, 0, 0, 0.87); }
  .b-table__td {
    background-color: #fff; }
    .b-table__td_tbody {
      border: 1px solid #e8e8e8; }
    .b-table__td_actions {
      width: 100px;
      text-align: center; }
      .b-table__td_actions a {
        text-decoration: none; }
  .b-table__th {
    background-color: #f9fafb;
    font-weight: 500;
    letter-spacing: -0.5px;
    font-size: 0.9rem;
    color: #6a6a6a; }
  .b-table__td_definition, .b-table__th_definition {
    width: 260px; }
  .b-table__td_transparent, .b-table__th_transparent {
    background-color: transparent; }
  .b-table__td_clear, .b-table__th_clear {
    background-color: transparent;
    border: 0; }
  .b-table__td_single, .b-table__th_single {
    border-width: 1px 0; }

.b-tooltip {
  display: inline-block;
  position: relative;
  overflow: visible; }
  .b-tooltip::before, .b-tooltip::after {
    transition: all 0.2s ease;
    font-family: "Apercu Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 0.9rem;
    font-style: normal;
    font-weight: bold;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    z-index: 5; }
  .b-tooltip::before {
    position: absolute;
    border: 0.6em solid transparent;
    content: ''; }
  .b-tooltip::after {
    position: absolute;
    padding: 0.25em 0.5em;
    border-radius: 0.28571rem;
    white-space: nowrap;
    content: attr(data-tooltip);
    visibility: hidden; }
  .b-tooltip_default::after {
    border: 1px solid #000;
    background-color: #000;
    color: #fff; }
  .b-tooltip_default.b-tooltip_top::before {
    border-top-color: #000 !important; }
  .b-tooltip_default.b-tooltip_right::before {
    border-right-color: #000 !important; }
  .b-tooltip_default.b-tooltip_bottom::before {
    border-bottom-color: #000 !important; }
  .b-tooltip_default.b-tooltip_left::before {
    border-left-color: #000 !important; }
  .b-tooltip_white::after {
    border: 1px solid #f4f4f4;
    background-color: #f4f4f4;
    color: #535353; }
  .b-tooltip_white.b-tooltip_top::before {
    border-top-color: #f4f4f4 !important; }
  .b-tooltip_white.b-tooltip_right::before {
    border-right-color: #f4f4f4 !important; }
  .b-tooltip_white.b-tooltip_bottom::before {
    border-bottom-color: #f4f4f4 !important; }
  .b-tooltip_white.b-tooltip_left::before {
    border-left-color: #f4f4f4 !important; }
  .b-tooltip_error::after {
    border: 1px solid #e74c3c;
    background-color: #e74c3c;
    color: #fff; }
  .b-tooltip_error.b-tooltip_top::before {
    border-top-color: #e74c3c !important; }
  .b-tooltip_error.b-tooltip_right::before {
    border-right-color: #e74c3c !important; }
  .b-tooltip_error.b-tooltip_bottom::before {
    border-bottom-color: #e74c3c !important; }
  .b-tooltip_error.b-tooltip_left::before {
    border-left-color: #e74c3c !important; }
  .b-tooltip_primary::after {
    border: 1px solid;
    color: #fff; }
  .b-tooltip_primary.b-tooltip_top::before {
    border-top-color: !important; }
  .b-tooltip_primary.b-tooltip_right::before {
    border-right-color: !important; }
  .b-tooltip_primary.b-tooltip_bottom::before {
    border-bottom-color: !important; }
  .b-tooltip_primary.b-tooltip_left::before {
    border-left-color: !important; }
  .b-tooltip_success::after {
    border: 1px solid #27ae60;
    background-color: #27ae60;
    color: #fff; }
  .b-tooltip_success.b-tooltip_top::before {
    border-top-color: #27ae60 !important; }
  .b-tooltip_success.b-tooltip_right::before {
    border-right-color: #27ae60 !important; }
  .b-tooltip_success.b-tooltip_bottom::before {
    border-bottom-color: #27ae60 !important; }
  .b-tooltip_success.b-tooltip_left::before {
    border-left-color: #27ae60 !important; }
  .b-tooltip:hover::before, .b-tooltip:hover::after {
    opacity: 1;
    visibility: visible;
    margin: 0; }
  .b-tooltip_top::before, .b-tooltip_top::after {
    margin-top: -10px; }
  .b-tooltip_top::before {
    top: 0;
    left: 50%;
    transform: translate(-50%, -1em); }
  .b-tooltip_top::after {
    top: 0;
    left: 50%;
    transform: translate(-50%, -3em); }
  .b-tooltip_right::before, .b-tooltip_right::after {
    margin-left: -10px; }
  .b-tooltip_right::before {
    top: 50%;
    left: 100%;
    transform: translate(0, -50%); }
  .b-tooltip_right::after {
    top: 50%;
    left: 100%;
    transform: translate(1em, -50%); }
  .b-tooltip_bottom::before, .b-tooltip_bottom::after {
    margin-bottom: -10px; }
  .b-tooltip_bottom::before {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 1em); }
  .b-tooltip_bottom::after {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 3em); }
  .b-tooltip_left::before, .b-tooltip_left::after {
    margin-right: -10px; }
  .b-tooltip_left::before {
    top: 50%;
    right: 100%;
    transform: translate(0, -50%); }
  .b-tooltip_left::after {
    top: 50%;
    right: 100%;
    transform: translate(-1em, -50%); }

@keyframes progress-pattern {
  100% {
    background-position: 100px 0; } }

.b-progress {
  border: none;
  appearance: none;
  height: 12px;
  display: inline-block;
  vertical-align: baseline;
  border-radius: 0.18rem;
  background-color: #ededed;
  position: relative;
  min-width: 120px; }
  .b-progress__value {
    border-radius: 0.18rem;
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    height: 12px;
    background-color: #6666FF; }
    .b-progress__value_disabled {
      background-color: #f0f2f7; }
    .b-progress__value_white {
      background-color: #fff; }
    .b-progress__value_success {
      background-color: #27ae60; }
    .b-progress__value_error {
      background-color: #e74c3c; }
    .b-progress__value_text {
      background-color: #A298F1; }
    .b-progress__value_heading {
      background-color: #000; }
    .b-progress__value_placeholder {
      background-color: #a298f1; }
    .b-progress__value_divider {
      background-color: rgba(34, 36, 38, 0.15); }
    .b-progress__value_secondary {
      background-color: #00E2A7; }
    .b-progress__value_green {
      background-color: #00E2A7; }
    .b-progress__value_button {
      background-color: #1b1b73; }
    .b-progress__value_subscribe {
      background-color: #2C2C99; }
    .b-progress__value_notification-background {
      background-color: #212529; }
    .b-progress__value_pattern {
      background-image: linear-gradient(-45deg, transparent 33%, rgba(0, 0, 0, 0.1) 33%, rgba(0, 0, 0, 0.1) 66%, transparent 66%);
      background-size: 35px 20px;
      animation: progress-pattern 5s linear infinite; }

.b-dropdown {
  position: relative; }
  .b-dropdown__toggle {
    cursor: pointer; }
  .b-dropdown__menu {
    min-width: 180px;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    transform: scale(0.85) translateY(10px);
    transition: all 0.15s ease;
    padding: 0.5rem 0;
    visibility: hidden;
    position: absolute;
    background-color: #fff;
    border-radius: 0.28571rem;
    user-select: none;
    opacity: 0;
    top: auto;
    left: auto; }
    .b-dropdown__menu::after, .b-dropdown__menu::before {
      bottom: 100%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      left: 1em; }
    .b-dropdown__menu::after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #fff;
      border-width: 4px;
      margin-left: -4px; }
    .b-dropdown__menu::before {
      border-color: rgba(212, 212, 213, 0);
      border-bottom-color: #d4d4d5;
      border-width: 5px;
      margin-left: -5px; }
    .b-dropdown__menu_open {
      visibility: visible;
      transform: scale(1);
      z-index: 2;
      opacity: 1; }
  .b-dropdown__divider {
    height: 1px;
    background-color: #d4d4d5;
    display: block;
    margin: calc(0.5rem + 1px) 0 0.5rem 0; }
  .b-dropdown__item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.2rem 1rem;
    display: block;
    text-decoration: none;
    color: #A298F1;
    font-size: 0.95rem; }
    .b-dropdown__item:hover {
      background-color: #6666FF;
      color: #fff; }

.noty_layout_mixin, #noty_layout__top, #noty_layout__topLeft, #noty_layout__topCenter, #noty_layout__topRight, #noty_layout__bottom, #noty_layout__bottomLeft, #noty_layout__bottomCenter, #noty_layout__bottomRight, #noty_layout__center, #noty_layout__centerLeft, #noty_layout__centerRight {
  position: fixed;
  margin: 0;
  padding: 0;
  z-index: 9999999;
  transform: translateZ(0) scale(1, 1);
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  filter: blur(0);
  -webkit-filter: blur(0);
  max-width: 90%; }

#noty_layout__top {
  top: 0;
  left: 5%;
  width: 90%; }

#noty_layout__topLeft {
  top: 20px;
  left: 20px;
  width: 325px; }

#noty_layout__topCenter {
  top: 5%;
  left: 50%;
  width: 325px;
  transform: translate(calc(-50% - .5px)) translateZ(0) scale(1, 1); }

#noty_layout__topRight {
  top: 20px;
  right: 20px;
  width: 325px; }

#noty_layout__bottom {
  bottom: 0;
  left: 5%;
  width: 90%; }

#noty_layout__bottomLeft {
  bottom: 20px;
  left: 20px;
  width: 325px; }

#noty_layout__bottomCenter {
  bottom: 5%;
  left: 50%;
  width: 325px;
  transform: translate(calc(-50% - .5px)) translateZ(0) scale(1, 1); }

#noty_layout__bottomRight {
  bottom: 20px;
  right: 20px;
  width: 325px; }

#noty_layout__center {
  top: 50%;
  left: 50%;
  width: 325px;
  transform: translate(calc(-50% - .5px), calc(-50% - .5px)) translateZ(0) scale(1, 1); }

#noty_layout__centerLeft {
  top: 50%;
  left: 20px;
  width: 325px;
  transform: translate(0, calc(-50% - .5px)) translateZ(0) scale(1, 1); }

#noty_layout__centerRight {
  top: 50%;
  right: 20px;
  width: 325px;
  transform: translate(0, calc(-50% - .5px)) translateZ(0) scale(1, 1); }

.noty_progressbar {
  display: none; }

.noty_has_timeout.noty_has_progressbar .noty_progressbar {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 100%;
  background-color: #646464;
  opacity: 0.2;
  filter: alpha(opacity=10); }

.noty_bar {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate(0, 0) translateZ(0) scale(1, 1);
  transform: translate(0, 0) scale(1, 1);
  -webkit-font-smoothing: subpixel-antialiased;
  overflow: hidden; }

.noty_effects_open {
  opacity: 0;
  transform: translate(50%);
  animation: noty_anim_in 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  animation-fill-mode: forwards; }

.noty_effects_close {
  animation: noty_anim_out 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  animation-fill-mode: forwards; }

.noty_fix_effects_height {
  animation: noty_anim_height 75ms ease-out; }

.noty_close_with_click {
  cursor: pointer; }

.noty_close_button {
  position: absolute;
  top: 2px;
  right: 2px;
  font-weight: bold;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  cursor: pointer;
  transition: all .2s ease-out; }

.noty_close_button:hover {
  background-color: rgba(0, 0, 0, 0.1); }

.noty_modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 10000;
  opacity: .3;
  left: 0;
  top: 0; }

.noty_modal.noty_modal_open {
  opacity: 0;
  animation: noty_modal_in .3s ease-out; }

.noty_modal.noty_modal_close {
  animation: noty_modal_out .3s ease-out;
  animation-fill-mode: forwards; }

@keyframes noty_modal_in {
  100% {
    opacity: .3; } }

@keyframes noty_modal_out {
  100% {
    opacity: 0; } }

@keyframes noty_modal_out {
  100% {
    opacity: 0; } }

@keyframes noty_anim_in {
  100% {
    transform: translate(0);
    opacity: 1; } }

@keyframes noty_anim_out {
  100% {
    transform: translate(50%);
    opacity: 0; } }

@keyframes noty_anim_height {
  100% {
    height: 0; } }

@font-face {
  font-family: 'Apercu Pro';
  src: url("../fonts/ApercuPro.eot");
  src: local("../fonts/ApercuPro"), local("../fonts/ApercuPro"), url("../fonts/ApercuPro.eot?#iefix") format("embedded-opentype"), url("../fonts/ApercuPro.woff") format("woff"), url("../fonts/ApercuPro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Apercu Pro';
  src: url("../fonts/ApercuPro-Bold.eot");
  src: local("../fonts/ApercuPro-Bold"), local("../fonts/ApercuPro-Bold"), url("../fonts/ApercuPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/ApercuPro-Bold.woff") format("woff"), url("../fonts/ApercuPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Apercu Pro';
  src: url("../fonts/ApercuPro-Light.eot");
  src: local("ApercuPro-Light"), local("ApercuPro-Light"), url("../fonts/ApercuPro-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/ApercuPro-Light.woff") format("woff"), url("../fonts/ApercuPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "socicon";
  src: url("../fonts/socicon.eot");
  src: url("../fonts/socicon.eot?#iefix") format("embedded-opentype"), url("../fonts/socicon.woff") format("woff"), url("../fonts/socicon.ttf") format("truetype"), url("../fonts/socicon.svg#socicon") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  font-family: "socicon" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="socicon-"]:before,
[class*=" socicon-"]:before {
  font-family: "socicon" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.socicon-modelmayhem:before {
  content: "\e000"; }

.socicon-mixcloud:before {
  content: "\e001"; }

.socicon-drupal:before {
  content: "\e002"; }

.socicon-swarm:before {
  content: "\e003"; }

.socicon-istock:before {
  content: "\e004"; }

.socicon-yammer:before {
  content: "\e005"; }

.socicon-ello:before {
  content: "\e006"; }

.socicon-stackoverflow:before {
  content: "\e007"; }

.socicon-persona:before {
  content: "\e008"; }

.socicon-triplej:before {
  content: "\e009"; }

.socicon-houzz:before {
  content: "\e00a"; }

.socicon-rss:before {
  content: "\e00b"; }

.socicon-paypal:before {
  content: "\e00c"; }

.socicon-odnoklassniki:before {
  content: "\e00d"; }

.socicon-airbnb:before {
  content: "\e00e"; }

.socicon-periscope:before {
  content: "\e00f"; }

.socicon-outlook:before {
  content: "\e010"; }

.socicon-coderwall:before {
  content: "\e011"; }

.socicon-tripadvisor:before {
  content: "\e012"; }

.socicon-appnet:before {
  content: "\e013"; }

.socicon-goodreads:before {
  content: "\e014"; }

.socicon-tripit:before {
  content: "\e015"; }

.socicon-lanyrd:before {
  content: "\e016"; }

.socicon-slideshare:before {
  content: "\e017"; }

.socicon-buffer:before {
  content: "\e018"; }

.socicon-disqus:before {
  content: "\e019"; }

.socicon-vkontakte:before {
  content: "\e01a"; }

.socicon-whatsapp:before {
  content: "\e01b"; }

.socicon-patreon:before {
  content: "\e01c"; }

.socicon-storehouse:before {
  content: "\e01d"; }

.socicon-pocket:before {
  content: "\e01e"; }

.socicon-mail:before {
  content: "\e01f"; }

.socicon-blogger:before {
  content: "\e020"; }

.socicon-technorati:before {
  content: "\e021"; }

.socicon-reddit:before {
  content: "\e022"; }

.socicon-dribbble:before {
  content: "\e023"; }

.socicon-stumbleupon:before {
  content: "\e024"; }

.socicon-digg:before {
  content: "\e025"; }

.socicon-envato:before {
  content: "\e026"; }

.socicon-behance:before {
  content: "\e027"; }

.socicon-delicious:before {
  content: "\e028"; }

.socicon-deviantart:before {
  content: "\e029"; }

.socicon-forrst:before {
  content: "\e02a"; }

.socicon-play:before {
  content: "\e02b"; }

.socicon-zerply:before {
  content: "\e02c"; }

.socicon-wikipedia:before {
  content: "\e02d"; }

.socicon-apple:before {
  content: "\e02e"; }

.socicon-flattr:before {
  content: "\e02f"; }

.socicon-github:before {
  content: "\e030"; }

.socicon-renren:before {
  content: "\e031"; }

.socicon-friendfeed:before {
  content: "\e032"; }

.socicon-newsvine:before {
  content: "\e033"; }

.socicon-identica:before {
  content: "\e034"; }

.socicon-bebo:before {
  content: "\e035"; }

.socicon-zynga:before {
  content: "\e036"; }

.socicon-steam:before {
  content: "\e037"; }

.socicon-xbox:before {
  content: "\e038"; }

.socicon-windows:before {
  content: "\e039"; }

.socicon-qq:before {
  content: "\e03a"; }

.socicon-douban:before {
  content: "\e03b"; }

.socicon-meetup:before {
  content: "\e03c"; }

.socicon-playstation:before {
  content: "\e03d"; }

.socicon-android:before {
  content: "\e03e"; }

.socicon-snapchat:before {
  content: "\e03f"; }

.socicon-twitter:before {
  content: "\e040"; }

.socicon-facebook:before {
  content: "\e041"; }

.socicon-googleplus:before {
  content: "\e042"; }

.socicon-pinterest:before {
  content: "\e043"; }

.socicon-foursquare:before {
  content: "\e044"; }

.socicon-yahoo:before {
  content: "\e045"; }

.socicon-skype:before {
  content: "\e046"; }

.socicon-yelp:before {
  content: "\e047"; }

.socicon-feedburner:before {
  content: "\e048"; }

.socicon-linkedin:before {
  content: "\e049"; }

.socicon-viadeo:before {
  content: "\e04a"; }

.socicon-xing:before {
  content: "\e04b"; }

.socicon-myspace:before {
  content: "\e04c"; }

.socicon-soundcloud:before {
  content: "\e04d"; }

.socicon-spotify:before {
  content: "\e04e"; }

.socicon-grooveshark:before {
  content: "\e04f"; }

.socicon-lastfm:before {
  content: "\e050"; }

.socicon-youtube:before {
  content: "\e051"; }

.socicon-vimeo:before {
  content: "\e052"; }

.socicon-dailymotion:before {
  content: "\e053"; }

.socicon-vine:before {
  content: "\e054"; }

.socicon-flickr:before {
  content: "\e055"; }

.socicon-500px:before {
  content: "\e056"; }

.socicon-wordpress:before {
  content: "\e058"; }

.socicon-tumblr:before {
  content: "\e059"; }

.socicon-twitch:before {
  content: "\e05a"; }

.socicon-8tracks:before {
  content: "\e05b"; }

.socicon-amazon:before {
  content: "\e05c"; }

.socicon-icq:before {
  content: "\e05d"; }

.socicon-smugmug:before {
  content: "\e05e"; }

.socicon-ravelry:before {
  content: "\e05f"; }

.socicon-weibo:before {
  content: "\e060"; }

.socicon-baidu:before {
  content: "\e061"; }

.socicon-angellist:before {
  content: "\e062"; }

.socicon-ebay:before {
  content: "\e063"; }

.socicon-imdb:before {
  content: "\e064"; }

.socicon-stayfriends:before {
  content: "\e065"; }

.socicon-residentadvisor:before {
  content: "\e066"; }

.socicon-google:before {
  content: "\e067"; }

.socicon-yandex:before {
  content: "\e068"; }

.socicon-sharethis:before {
  content: "\e069"; }

.socicon-bandcamp:before {
  content: "\e06a"; }

.socicon-itunes:before {
  content: "\e06b"; }

.socicon-deezer:before {
  content: "\e06c"; }

.socicon-telegram:before {
  content: "\e06e"; }

.socicon-openid:before {
  content: "\e06f"; }

.socicon-amplement:before {
  content: "\e070"; }

.socicon-viber:before {
  content: "\e071"; }

.socicon-zomato:before {
  content: "\e072"; }

.socicon-draugiem:before {
  content: "\e074"; }

.socicon-endomodo:before {
  content: "\e075"; }

.socicon-filmweb:before {
  content: "\e076"; }

.socicon-stackexchange:before {
  content: "\e077"; }

.socicon-wykop:before {
  content: "\e078"; }

.socicon-teamspeak:before {
  content: "\e079"; }

.socicon-teamviewer:before {
  content: "\e07a"; }

.socicon-ventrilo:before {
  content: "\e07b"; }

.socicon-younow:before {
  content: "\e07c"; }

.socicon-raidcall:before {
  content: "\e07d"; }

.socicon-mumble:before {
  content: "\e07e"; }

.socicon-medium:before {
  content: "\e06d"; }

.socicon-bebee:before {
  content: "\e07f"; }

.socicon-hitbox:before {
  content: "\e080"; }

.socicon-reverbnation:before {
  content: "\e081"; }

.socicon-formulr:before {
  content: "\e082"; }

.socicon-instagram:before {
  content: "\e057"; }

.socicon-battlenet:before {
  content: "\e083"; }

.socicon-chrome:before {
  content: "\e084"; }

.socicon-discord:before {
  content: "\e086"; }

.socicon-issuu:before {
  content: "\e087"; }

.socicon-macos:before {
  content: "\e088"; }

.socicon-firefox:before {
  content: "\e089"; }

.socicon-opera:before {
  content: "\e08d"; }

.socicon-keybase:before {
  content: "\e090"; }

.socicon-alliance:before {
  content: "\e091"; }

.socicon-livejournal:before {
  content: "\e092"; }

.socicon-googlephotos:before {
  content: "\e093"; }

.socicon-horde:before {
  content: "\e094"; }

.socicon-etsy:before {
  content: "\e095"; }

.socicon-zapier:before {
  content: "\e096"; }

.socicon-google-scholar:before {
  content: "\e097"; }

.socicon-researchgate:before {
  content: "\e098"; }

.socicon-wechat:before {
  content: "\e099"; }

.socicon-strava:before {
  content: "\e09a"; }

.socicon-line:before {
  content: "\e09b"; }

.socicon-lyft:before {
  content: "\e09c"; }

.socicon-uber:before {
  content: "\e09d"; }

.socicon-songkick:before {
  content: "\e09e"; }

.socicon-viewbug:before {
  content: "\e09f"; }

.socicon-googlegroups:before {
  content: "\e0a0"; }

.socicon-quora:before {
  content: "\e073"; }

.socicon-diablo:before {
  content: "\e085"; }

.socicon-blizzard:before {
  content: "\e0a1"; }

.socicon-hearthstone:before {
  content: "\e08b"; }

.socicon-heroes:before {
  content: "\e08a"; }

.socicon-overwatch:before {
  content: "\e08c"; }

.socicon-warcraft:before {
  content: "\e08e"; }

.socicon-starcraft:before {
  content: "\e08f"; }

.socicon-beam:before {
  content: "\e0a2"; }

.socicon-curse:before {
  content: "\e0a3"; }

.socicon-player:before {
  content: "\e0a4"; }

.socicon-streamjar:before {
  content: "\e0a5"; }

.socicon-nintendo:before {
  content: "\e0a6"; }

.socicon-hellocoton:before {
  content: "\e0a7"; }

a {
  text-decoration: none; }

.b-col__center {
  display: flex;
  justify-content: center; }

.b-col__custom {
  background-color: #1D2049; }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 40px;
  height: 80px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before, .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

.noty_layout_mixin, #noty_layout__top, #noty_layout__topLeft, #noty_layout__topCenter, #noty_layout__topRight, #noty_layout__bottom, #noty_layout__bottomLeft, #noty_layout__bottomCenter, #noty_layout__bottomRight, #noty_layout__center, #noty_layout__centerLeft, #noty_layout__centerRight {
  position: fixed;
  margin: 0;
  padding: 0;
  z-index: 9999999;
  transform: translateZ(0) scale(1, 1);
  backface-visibility: hidden;
  filter: blur(0);
  max-width: 90%; }

#noty_layout__top {
  top: 0;
  left: 5%;
  width: 90%; }

#noty_layout__topLeft {
  top: 20px;
  left: 20px;
  width: 325px; }

#noty_layout__topCenter {
  top: 5%;
  left: 50%;
  width: 325px;
  transform: translate(calc(-50% - .5px)) translateZ(0) scale(1, 1); }

#noty_layout__topRight {
  top: 20px;
  right: 20px;
  width: 325px; }

#noty_layout__bottom {
  bottom: 0;
  left: 5%;
  width: 90%; }

#noty_layout__bottomLeft {
  bottom: 20px;
  left: 20px;
  width: 325px; }

#noty_layout__bottomCenter {
  bottom: 5%;
  left: 50%;
  width: 325px;
  transform: translate(calc(-50% - .5px)) translateZ(0) scale(1, 1); }

#noty_layout__bottomRight {
  bottom: 20px;
  right: 20px;
  width: 325px; }

#noty_layout__center {
  top: 50%;
  left: 50%;
  width: 325px;
  transform: translate(calc(-50% - .5px), calc(-50% - .5px)) translateZ(0) scale(1, 1); }

#noty_layout__centerLeft {
  top: 50%;
  left: 20px;
  width: 325px;
  transform: translate(0, calc(-50% - .5px)) translateZ(0) scale(1, 1); }

#noty_layout__centerRight {
  top: 50%;
  right: 20px;
  width: 325px;
  transform: translate(0, calc(-50% - .5px)) translateZ(0) scale(1, 1); }

.noty_progressbar {
  display: none; }

.noty_has_timeout.noty_has_progressbar .noty_progressbar {
  display: block;
  opacity: 1;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 100%;
  background-color: #646464; }

.noty_bar {
  transform: translate(0, 0) scale(1, 1);
  overflow: hidden; }

.noty_effects_open {
  opacity: 0;
  transform: translate(50%);
  animation: noty_anim_in 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  animation-fill-mode: forwards; }

.noty_effects_close {
  animation: noty_anim_out 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  animation-fill-mode: forwards; }

.noty_fix_effects_height {
  animation: noty_anim_height 75ms ease-out; }

.noty_close_with_click {
  cursor: pointer; }

.noty_close_button {
  position: absolute;
  top: 2px;
  right: 2px;
  font-weight: bold;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 0;
  cursor: pointer;
  transition: all .2s ease-out; }

.noty_close_button:hover {
  background-color: rgba(0, 0, 0, 0.1); }

.noty_modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 10000;
  opacity: .3;
  left: 0;
  top: 0; }

.noty_modal.noty_modal_open {
  opacity: 0;
  animation: noty_modal_in .3s ease-out; }

.noty_modal.noty_modal_close {
  animation: noty_modal_out .3s ease-out;
  animation-fill-mode: forwards; }

@keyframes noty_modal_in {
  100% {
    opacity: .3; } }

@keyframes noty_modal_out {
  100% {
    opacity: 0; } }

@keyframes noty_anim_in {
  100% {
    transform: translate(0);
    opacity: 1; } }

@keyframes noty_anim_out {
  100% {
    transform: translate(50%);
    opacity: 0; } }

@keyframes noty_anim_height {
  100% {
    height: 0; } }

.noty_theme__mint.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  border-radius: 0;
  position: relative; }
  .noty_theme__mint.noty_bar .noty_body {
    padding: 1rem 1.4rem; }
  .noty_theme__mint.noty_bar .noty_buttons {
    padding: 10px; }

.noty_theme__mint.noty_type__alert,
.noty_theme__mint.noty_type__notification {
  background-color: #212529;
  color: #2F2F2F; }
  .noty_theme__mint.noty_type__alert .noty_progressbar,
  .noty_theme__mint.noty_type__notification .noty_progressbar {
    background-color: #D1D1D1; }

.noty_theme__mint.noty_type__warning {
  background-color: #212529;
  color: #fff; }
  .noty_theme__mint.noty_type__warning .noty_progressbar {
    background-color: #E89F3C; }

.noty_theme__mint.noty_type__error {
  background-color: #212529;
  color: #fff; }
  .noty_theme__mint.noty_type__error .noty_progressbar {
    background-color: #CA5A65; }

.noty_theme__mint.noty_type__info,
.noty_theme__mint.noty_type__information {
  background-color: #212529;
  color: #fff; }
  .noty_theme__mint.noty_type__info .noty_progressbar,
  .noty_theme__mint.noty_type__information .noty_progressbar {
    background-color: #7473E8; }

.noty_theme__mint.noty_type__success {
  background-color: #212529;
  color: #fff; }
  .noty_theme__mint.noty_type__success .noty_progressbar {
    background-color: #27ae60; }

body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms; }

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0; }
  body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
    transition-delay: 50ms; }

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms; }

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0; }
  body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
    transition-delay: 100ms; }

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms; }

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0; }
  body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
    transition-delay: 150ms; }

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms; }

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0; }
  body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
    transition-delay: 200ms; }

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms; }

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0; }
  body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
    transition-delay: 250ms; }

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms; }

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0; }
  body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
    transition-delay: 300ms; }

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms; }

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0; }
  body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
    transition-delay: 350ms; }

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms; }

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0; }
  body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
    transition-delay: 400ms; }

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms; }

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0; }
  body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
    transition-delay: 450ms; }

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms; }

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0; }
  body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
    transition-delay: 500ms; }

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms; }

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0; }
  body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
    transition-delay: 550ms; }

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms; }

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0; }
  body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
    transition-delay: 600ms; }

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms; }

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0; }
  body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
    transition-delay: 650ms; }

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms; }

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0; }
  body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
    transition-delay: 700ms; }

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms; }

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0; }
  body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
    transition-delay: 750ms; }

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms; }

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0; }
  body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
    transition-delay: 800ms; }

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms; }

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0; }
  body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
    transition-delay: 850ms; }

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms; }

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0; }
  body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
    transition-delay: 900ms; }

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms; }

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0; }
  body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
    transition-delay: 950ms; }

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms; }

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0; }
  body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
    transition-delay: 1000ms; }

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms; }

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0; }
  body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
    transition-delay: 1050ms; }

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms; }

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0; }
  body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
    transition-delay: 1100ms; }

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms; }

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0; }
  body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
    transition-delay: 1150ms; }

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms; }

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0; }
  body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
    transition-delay: 1200ms; }

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms; }

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0; }
  body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
    transition-delay: 1250ms; }

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms; }

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0; }
  body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
    transition-delay: 1300ms; }

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms; }

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0; }
  body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
    transition-delay: 1350ms; }

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms; }

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0; }
  body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
    transition-delay: 1400ms; }

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms; }

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0; }
  body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
    transition-delay: 1450ms; }

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms; }

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0; }
  body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
    transition-delay: 1500ms; }

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms; }

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0; }
  body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
    transition-delay: 1550ms; }

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms; }

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0; }
  body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
    transition-delay: 1600ms; }

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms; }

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0; }
  body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
    transition-delay: 1650ms; }

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms; }

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0; }
  body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
    transition-delay: 1700ms; }

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms; }

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0; }
  body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
    transition-delay: 1750ms; }

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms; }

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0; }
  body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
    transition-delay: 1800ms; }

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms; }

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0; }
  body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
    transition-delay: 1850ms; }

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms; }

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0; }
  body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
    transition-delay: 1900ms; }

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms; }

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0; }
  body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
    transition-delay: 1950ms; }

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms; }

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0; }
  body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
    transition-delay: 2000ms; }

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms; }

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0; }
  body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
    transition-delay: 2050ms; }

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms; }

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0; }
  body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
    transition-delay: 2100ms; }

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms; }

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0; }
  body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
    transition-delay: 2150ms; }

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms; }

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0; }
  body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
    transition-delay: 2200ms; }

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms; }

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0; }
  body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
    transition-delay: 2250ms; }

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms; }

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0; }
  body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
    transition-delay: 2300ms; }

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms; }

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0; }
  body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
    transition-delay: 2350ms; }

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms; }

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0; }
  body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
    transition-delay: 2400ms; }

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms; }

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0; }
  body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
    transition-delay: 2450ms; }

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms; }

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0; }
  body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
    transition-delay: 2500ms; }

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms; }

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0; }
  body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
    transition-delay: 2550ms; }

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms; }

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0; }
  body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
    transition-delay: 2600ms; }

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms; }

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0; }
  body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
    transition-delay: 2650ms; }

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms; }

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0; }
  body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
    transition-delay: 2700ms; }

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms; }

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0; }
  body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
    transition-delay: 2750ms; }

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms; }

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0; }
  body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
    transition-delay: 2800ms; }

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms; }

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0; }
  body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
    transition-delay: 2850ms; }

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms; }

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0; }
  body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
    transition-delay: 2900ms; }

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms; }

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0; }
  body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
    transition-delay: 2950ms; }

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms; }

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0; }
  body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
    transition-delay: 3000ms; }

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1); }

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='fade'][data-aos^='fade'].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

[data-aos='fade-up'] {
  transform: translate3d(0, 100px, 0); }

[data-aos='fade-down'] {
  transform: translate3d(0, -100px, 0); }

[data-aos='fade-right'] {
  transform: translate3d(-100px, 0, 0); }

[data-aos='fade-left'] {
  transform: translate3d(100px, 0, 0); }

[data-aos='fade-up-right'] {
  transform: translate3d(-100px, 100px, 0); }

[data-aos='fade-up-left'] {
  transform: translate3d(100px, 100px, 0); }

[data-aos='fade-down-right'] {
  transform: translate3d(-100px, -100px, 0); }

[data-aos='fade-down-left'] {
  transform: translate3d(100px, -100px, 0); }

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }

[data-aos='zoom-in'] {
  transform: scale(0.6); }

[data-aos='zoom-in-up'] {
  transform: translate3d(0, 100px, 0) scale(0.6); }

[data-aos='zoom-in-down'] {
  transform: translate3d(0, -100px, 0) scale(0.6); }

[data-aos='zoom-in-right'] {
  transform: translate3d(-100px, 0, 0) scale(0.6); }

[data-aos='zoom-in-left'] {
  transform: translate3d(100px, 0, 0) scale(0.6); }

[data-aos='zoom-out'] {
  transform: scale(1.2); }

[data-aos='zoom-out-up'] {
  transform: translate3d(0, 100px, 0) scale(1.2); }

[data-aos='zoom-out-down'] {
  transform: translate3d(0, -100px, 0) scale(1.2); }

[data-aos='zoom-out-right'] {
  transform: translate3d(-100px, 0, 0) scale(1.2); }

[data-aos='zoom-out-left'] {
  transform: translate3d(100px, 0, 0) scale(1.2); }

/**
 * Slide animations
 */
[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform; }
  [data-aos^='slide'][data-aos^='slide'].aos-animate {
    transform: translate3d(0, 0, 0); }

[data-aos='slide-up'] {
  transform: translate3d(0, 100%, 0); }

[data-aos='slide-down'] {
  transform: translate3d(0, -100%, 0); }

[data-aos='slide-right'] {
  transform: translate3d(-100%, 0, 0); }

[data-aos='slide-left'] {
  transform: translate3d(100%, 0, 0); }

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform; }

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg); }
  [data-aos='flip-left'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg); }
  [data-aos='flip-right'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg); }
  [data-aos='flip-up'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg); }
  [data-aos='flip-down'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent; }

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto; }

.slick-prev:before {
  content: '←'; }

[dir='rtl'] .slick-prev:before {
  content: '→'; }

.slick-next {
  right: -25px; }

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px; }

.slick-next:before {
  content: '→'; }

[dir='rtl'] .slick-next:before {
  content: '←'; }

/* Dots */
.slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 15px;
  height: 15px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  border-radius: 10px; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: transparent; }

.b-input {
  border-bottom: 1px solid #a298f1;
  height: 56px;
  background-color: transparent;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  color: #fff; }
  .b-input:hover:not(:focus) {
    box-shadow: none; }
  .b-input:focus, .b-input:active {
    box-shadow: none;
    border-bottom: 1px solid #fff; }
  .b-input_error {
    border-color: #ed7669; }

.b-body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  /* Support for IE. */
  font-feature-settings: 'liga';
  background-color: #1D2049; }

.b-spinner {
  background-color: #6666FF;
  display: block;
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  border-radius: 100%;
  opacity: 0;
  animation: animation-scaleout 0.9s infinite ease-in-out; }

@keyframes animation-scaleout {
  0% {
    opacity: 0.3;
    transform: scale(0); }
  100% {
    opacity: 1;
    transform: scale(1); } }

.b-heading {
  font-size: 40px;
  color: #fff;
  line-height: 1.5;
  text-align: left; }
  @media all and (max-width: 767px) {
    .b-heading {
      text-align: center; } }
  .b-heading_hero::before {
    content: ' ';
    width: 50px;
    height: 2px;
    background-color: #75B82F;
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-bottom: 1rem; }
    @media all and (max-width: 767px) {
      .b-heading_hero::before {
        left: 50%;
        transform: translateX(-50%); } }
  .b-heading_blue::before {
    content: ' ';
    width: 50px;
    height: 2px;
    background-color: #4488FF;
    position: relative;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    margin-bottom: 1rem; }
  .b-heading_green {
    text-align: center; }
    .b-heading_green::before {
      content: ' ';
      width: 50px;
      height: 2px;
      background-color: #75B82F;
      position: relative;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      margin-bottom: 1rem; }

.b-button {
  background: linear-gradient(90deg, rgba(9, 172, 204, 0.2), rgba(51, 146, 52, 0.2) 50%);
  border-radius: 40px;
  box-sizing: border-box;
  display: inline-block;
  height: 80px;
  letter-spacing: 1px;
  padding: 8px;
  position: relative;
  text-transform: uppercase;
  width: 320px;
  margin: 1rem 0;
  animation: pulse 2s infinite cubic-bezier(0.2, 0, 0, 1); }
  @media all and (max-width: 767px) {
    .b-button {
      width: 100%; } }
  @media only screen and (min-width: 992px) {
    .b-button {
      z-index: 2; } }
  .b-button:hover, .b-button:focus, .b-button:active {
    background: linear-gradient(90deg, rgba(9, 172, 204, 0.2), rgba(51, 146, 52, 0.2) 50%); }
  .b-button__back {
    background: linear-gradient(0.25turn, #09ACCC, #339234);
    color: #fff;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    transition: background .5s ease;
    position: relative; }
    .b-button__back:hover {
      z-index: 4; }
      .b-button__back:hover::before {
        opacity: 1;
        z-index: -2; }
    .b-button__back::before {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background: #75B82F;
      opacity: 0;
      transition: opacity 0.5s; }
  .b-button__text {
    z-index: 10;
    color: #fff;
    text-transform: uppercase; }

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(9, 172, 204, 0.7); }
  100% {
    box-shadow: 0 0 0 6px rgba(232, 76, 61, 0); } }
  .b-button_submit {
    background: linear-gradient(0.25turn, #09ACCC, #339234);
    height: 64px; }
    .b-button_submit::after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      border-radius: inherit;
      width: 100%;
      height: 100%;
      background: #75B82F;
      opacity: 0;
      transition: opacity 0.5s;
      z-index: -4; }
    .b-button_submit::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      border-radius: inherit;
      width: 100%;
      height: 100%;
      background: linear-gradient(0.25turn, #09ACCC, #339234);
      opacity: 1;
      transition: opacity 0.5s;
      z-index: -5; }
    .b-button_submit:hover::after {
      opacity: 1; }
  .b-button__light {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    box-sizing: border-box;
    height: 70px;
    width: 100%;
    letter-spacing: 1px;
    font-weight: 400;
    padding: 8px;
    position: relative;
    text-transform: uppercase;
    border: 2px solid #A298F1;
    color: #fff;
    margin-top: 21px;
    background: transparent;
    animation: pulse 2s infinite cubic-bezier(0.2, 0, 0, 1);
    transition: all .5s ease; }
    .b-button__light:hover {
      background: rgba(192, 194, 255, 0.5); }

.b-block {
  margin: 2rem 0; }
  .b-block_center {
    display: flex;
    justify-content: center; }

.b-form__row {
  margin-bottom: 3rem; }
  .b-form__row_button {
    text-align: center; }

.b-form-preorder {
  width: 100%;
  height: auto;
  padding: 1rem;
  text-align: center;
  background: linear-gradient(45deg, #9940A3, #521FD9); }
  @media only screen and (min-width: 992px) {
    .b-form-preorder {
      width: 600px;
      height: 500px;
      padding: 4rem 8rem;
      background-image: url("../images/popup_bg.svg");
      background-position: 50% 50%;
      background-size: contain;
      background-repeat: no-repeat; } }
  .b-form-preorder__icon {
    width: 68px;
    height: 59px;
    background-image: url("../images/icon_popup.svg");
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto 1rem auto; }
  .b-form-preorder__heading {
    font-size: 40px;
    font-family: 'Apercu Pro', sans-serif; }
  .b-form-preorder__text {
    font-size: 16px;
    font-weight: 200;
    margin: 1rem 0; }

.b-header__content {
  position: relative;
  padding: 2rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

.b-header__mobile {
  display: none; }
  @media all and (max-width: 767px) {
    .b-header__mobile {
      display: block; } }

.b-header__block {
  display: flex; }

.b-errors__error {
  color: #ed7669; }

.b-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.4rem;
  color: #fff; }
  .b-logo__img {
    background-image: url("../images/dreamtech.svg");
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    width: 50px;
    height: 50px; }

.b-menu {
  display: flex;
  animation: fade 2s; }
  @media all and (max-width: 767px) {
    .b-menu {
      display: none; } }
  .b-menu__link {
    color: #A298F1;
    text-transform: uppercase;
    margin-right: 1rem;
    transition: all .5s ease; }
    @media only screen and (min-width: 992px) {
      .b-menu__link {
        margin-right: 2rem; } }
    .b-menu__link:hover {
      color: #fff;
      transition: all .5s ease; }
  .b-menu__home {
    color: #A298F1;
    text-transform: uppercase;
    margin-right: 1rem;
    transition: all .5s ease; }
    @media only screen and (min-width: 992px) {
      .b-menu__home {
        margin-right: 2rem; } }
    .b-menu__home:hover {
      color: #fff;
      transition: all .5s ease; }

@keyframes fade {
  0% {
    opacity: 0;
    transform: translateX(100px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

.b-menu-mobile {
  display: none; }
  @media all and (max-width: 767px) {
    .b-menu-mobile {
      display: block; } }
  .b-menu-mobile__button-block {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -10px; }
  .b-menu-mobile__button {
    position: relative; }
    .b-menu-mobile__button span {
      display: block;
      width: 20px;
      height: 2px;
      background: #fff; }
      .b-menu-mobile__button span::before {
        content: ' ';
        position: absolute;
        top: 6px;
        display: block;
        width: 20px;
        height: 2px;
        background: #fff; }
      .b-menu-mobile__button span::after {
        content: ' ';
        position: absolute;
        top: -6px;
        display: block;
        width: 20px;
        height: 2px;
        background: #fff; }
  .b-menu-mobile__bg {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    cursor: pointer;
    transition: opacity 0.2s ease; }
    .b-menu-mobile__bg_open {
      left: 0;
      visibility: visible;
      opacity: 1; }
  .b-menu-mobile__menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    left: -100%;
    width: calc(100% - 30%);
    height: 100vh;
    top: 0;
    background: linear-gradient(#AD6CAD, #7336FF);
    box-sizing: border-box;
    z-index: 101;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease; }
    .b-menu-mobile__menu_open {
      left: 0;
      visibility: visible;
      opacity: 1;
      margin-left: -1rem; }
  .b-menu-mobile__link {
    color: #fff;
    margin-bottom: 1rem;
    margin-left: 15%;
    font-size: 1.2rem;
    text-transform: uppercase; }
  .b-menu-mobile__home {
    color: #fff;
    margin-bottom: 1rem;
    margin-left: 15%;
    font-size: 1.2rem;
    text-transform: uppercase; }

.b-hero__content {
  position: relative; }
  @media all and (max-width: 767px) {
    .b-hero__content {
      text-align: center; } }

.b-hero__left {
  z-index: 2;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media all and (max-width: 767px) {
    .b-hero__left {
      height: auto; } }

.b-hero__img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  z-index: -1;
  margin-left: 12rem; }
  @media all and (max-width: 767px) {
    .b-hero__img {
      display: none; } }
  @media only screen and (min-width: 992px) {
    .b-hero__img {
      right: 0;
      left: auto;
      margin: 0; } }

.b-hero__text {
  color: #A298F1;
  line-height: 2.5;
  margin-top: 1rem; }

.b-hero__icon {
  display: block;
  background-image: url("../images/hero-icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  width: 20px;
  height: 50px;
  margin-right: 1rem; }

.b-hero__skip {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #fff;
  margin-top: 1rem; }
  @media all and (max-width: 767px) {
    .b-hero__skip {
      display: none; } }

.b-hero__block {
  position: relative;
  display: flex;
  justify-content: center; }

.b-hero__our {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 118px;
  position: relative;
  margin: 2rem 0;
  color: #fff;
  text-transform: uppercase; }
  .b-hero__our::before {
    content: ' ';
    background-image: url("../images/hero-our-icon.svg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 33px;
    height: 118px;
    z-index: -2;
    animation-name: floating;
    animation-duration: 1.5s;
    animation-iteration-count: infinite; }

@keyframes floating {
  0% {
    transform: translate(-50%, -50%); }
  50% {
    transform: translate(-50%, -60%); }
  100% {
    transform: translate(-50%, -50%); } }

.b-hero__our-skip {
  color: #fff;
  position: absolute; }

.b-hero__br {
  display: block; }
  @media all and (max-width: 767px) {
    .b-hero__br {
      display: none; } }

.b-hero__mouse {
  position: relative;
  width: 20px;
  height: 30px;
  border: 2px solid #75B82F;
  border-radius: 20px;
  margin-right: 1rem; }

.b-hero__mouse-wheel {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3px;
  height: 5px;
  background: #75B82F;
  animation-name: mouse;
  animation-duration: 3s;
  animation-iteration-count: infinite; }

@keyframes mouse {
  0% {
    transform: translate(-50%, 0%); }
  50% {
    transform: translate(-50%, -80%); }
  100% {
    transform: translate(-50%, 0%); } }

.b-features {
  position: relative;
  min-width: 100%;
  overflow: hidden; }
  .b-features::before {
    content: ' ';
    background-image: url("../images/gradient/gradient2.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    width: 796px;
    height: 845px;
    position: absolute;
    top: 15%;
    right: 0;
    z-index: -2; }
    @media all and (max-width: 767px) {
      .b-features::before {
        top: 25%; } }
  .b-features::after {
    content: ' ';
    background-image: url("../images/gradient/gradient3.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    width: 940px;
    height: 862px;
    position: absolute;
    bottom: 17%;
    left: 0;
    z-index: -2; }
    @media all and (max-width: 1024px) {
      .b-features::after {
        bottom: 5%; } }
  .b-features__block {
    margin-bottom: 2rem;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem; }
    @media all and (max-width: 767px) {
      .b-features__block {
        flex-direction: column; } }
    @media all and (max-width: 767px) {
      .b-features__block_three {
        padding-top: 0 !important; } }
    @media all and (max-width: 991px) {
      .b-features__block_three {
        padding-top: 7rem; } }
  .b-features__head {
    display: block;
    width: 74px;
    height: 55px; }
    .b-features__head_one {
      background-image: url("../images/features_1.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain; }
    .b-features__head_two {
      background-image: url("../images/features_2.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain; }
    .b-features__head_three {
      background-image: url("../images/features_3.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain; }
  .b-features__heading {
    font-size: 40px;
    color: #fff; }
  .b-features__text {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    /* Support for IE. */
    font-feature-settings: 'liga';
    color: #A298F1; }
  .b-features__cart {
    padding-right: 2rem; }
  .b-features__icon {
    display: block;
    margin: 1rem 0; }
    .b-features__icon_server {
      background-image: url("../images/icon_features/server.svg");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      width: 71px;
      height: 62px; }
    .b-features__icon_rate {
      background-image: url("../images/icon_features/rate.svg");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      width: 63px;
      height: 62px; }
    .b-features__icon_bitcoin {
      background-image: url("../images/icon_features/bitcoin.svg");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      width: 65px;
      height: 64px; }
    .b-features__icon_web {
      background-image: url("../images/icon_features/web.svg");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      width: 67px;
      height: 58px; }
    .b-features__icon_circle {
      background-image: url("../images/icon_features/circle.svg");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      width: 61px;
      height: 68px; }
    .b-features__icon_kyc {
      background-image: url("../images/icon_features/kyc.svg");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      width: 61px;
      height: 68px; }
    .b-features__icon_bounty {
      background-image: url("../images/icon_features/bounty.svg");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      width: 61px;
      height: 68px; }
    .b-features__icon_statistic {
      background-image: url("../images/icon_features/statistic.svg");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      width: 61px;
      height: 68px; }
  .b-features__right_mrg {
    margin: 0; }
    @media only screen and (min-width: 992px) {
      .b-features__right_mrg {
        margin-left: 3rem; } }
  .b-features__invisible {
    display: none; }
    @media only screen and (min-width: 992px) {
      .b-features__invisible {
        display: block; } }
    @media all and (max-width: 991px) {
      .b-features__invisible_other {
        width: 380px; } }
    @media only screen and (min-width: 992px) {
      .b-features__invisible_other {
        width: auto; } }
  .b-features__visible {
    display: block; }
    @media all and (max-width: 767px) {
      .b-features__visible {
        display: none !important; } }
    @media only screen and (min-width: 992px) {
      .b-features__visible {
        display: none !important; } }
  .b-features__mobile-block {
    position: relative; }
  .b-features__mobile-img {
    position: absolute;
    top: -150px; }
    .b-features__mobile-img_relative {
      position: relative;
      left: -50px; }
      @media all and (max-width: 991px) {
        .b-features__mobile-img_relative {
          left: -270px; } }
      @media all and (max-width: 767px) {
        .b-features__mobile-img_relative {
          left: -270px;
          display: none; } }
    .b-features__mobile-img__three {
      top: -225px; }
  @media only screen and (min-width: 992px) {
    .b-features__img {
      width: 512px;
      height: 600px; } }
  @media only screen and (min-width: 992px) {
    .b-features__img_right {
      margin-right: 10rem; } }
  @media only screen and (min-width: 992px) {
    .b-features__img_left {
      margin-left: 10rem; } }
  .b-features__img_mobile {
    width: 100%; }
  .b-features__mobile {
    display: none; }
    @media all and (max-width: 767px) {
      .b-features__mobile {
        display: flex;
        justify-content: center;
        height: 300px; } }

.b-offer {
  position: relative;
  display: flex;
  align-items: center;
  height: 1100px;
  min-width: 100%;
  overflow: hidden; }
  @media all and (max-width: 767px) {
    .b-offer {
      height: 1500px; } }
  .b-offer::before {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -2;
    background-image: url("../images/offer_bg.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    width: 1100px;
    height: 1100px; }
    @media all and (max-width: 767px) {
      .b-offer::before {
        width: 1500px;
        height: 1500px; } }
  .b-offer__block {
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
    flex-direction: row;
    position: relative;
    margin-top: 2rem;
    width: 670px; }
    @media all and (max-width: 767px) {
      .b-offer__block {
        flex-direction: column;
        padding: 0;
        width: 100%; } }
    .b-offer__block::before {
      display: block;
      content: ' ';
      width: 2px;
      height: 360px;
      background: rgba(255, 255, 255, 0.2);
      position: absolute;
      top: 10%;
      left: 50%; }
      @media all and (max-width: 767px) {
        .b-offer__block::before {
          display: none; } }
  .b-offer__heading {
    color: #CEC9F5;
    font-weight: 600;
    margin-bottom: 1rem; }
  .b-offer__text {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    /* Support for IE. */
    font-feature-settings: 'liga';
    color: #A298F1; }
  .b-offer__icon {
    width: 184px;
    height: 144px;
    margin-bottom: 1rem; }
    .b-offer__icon_left {
      background-image: url("../images/offer_left.svg");
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat; }
    .b-offer__icon_right {
      background-image: url("../images/offer_right.svg");
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat; }
  .b-offer__left {
    margin-right: 4rem; }
    @media all and (max-width: 767px) {
      .b-offer__left {
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem; } }
  .b-offer__right {
    margin-left: 2rem; }
    @media all and (max-width: 767px) {
      .b-offer__right {
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; } }
  .b-offer__right, .b-offer__left {
    width: 500px; }
    @media all and (max-width: 767px) {
      .b-offer__right, .b-offer__left {
        width: auto; } }
  .b-offer__text {
    text-align: left; }
    @media all and (max-width: 767px) {
      .b-offer__text {
        text-align: center; } }

.b-tech__block {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0; }

.b-tech__heading {
  text-align: center; }

.b-contacts {
  margin-top: 2rem; }
  .b-contacts__form {
    width: 100%;
    margin: 0 auto; }
    @media only screen and (min-width: 992px) {
      .b-contacts__form {
        width: 750px; } }
  .b-contacts__form-area {
    border-bottom: 1px solid #a298f1;
    width: 100%;
    height: 56px;
    color: #fff;
    margin-top: 2rem;
    margin-bottom: 2rem; }
    @media only screen and (min-width: 992px) {
      .b-contacts__form-area {
        width: 750px; } }
    .b-contacts__form-area:focus, .b-contacts__form-area:active {
      border-bottom: 1px solid #fff; }
    .b-contacts__form-area::placeholder {
      color: #a298f1; }
  .b-contacts__row {
    display: block; }
    @media only screen and (min-width: 992px) {
      .b-contacts__row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1rem; } }

.b-footer__content {
  padding-top: 4rem;
  padding-bottom: 7rem;
  align-items: center; }
  @media only screen and (min-width: 992px) {
    .b-footer__content {
      display: grid;
      grid-template-columns: repeat(3, 1fr); } }

.b-footer__block_first {
  text-align: center; }
  @media only screen and (min-width: 992px) {
    .b-footer__block_first {
      text-align: left; } }

.b-footer__block_last {
  text-align: center; }
  @media only screen and (min-width: 992px) {
    .b-footer__block_last {
      text-align: right; } }

.b-footer__link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  /* Support for IE. */
  font-feature-settings: 'liga';
  color: #A298F1; }
  .b-footer__link:not(:first-of-type) {
    margin-left: 1rem; }

.b-footer__buttons {
  display: none;
  position: fixed;
  bottom: 50px;
  right: 50px; }
  @media only screen and (min-width: 992px) {
    .b-footer__buttons {
      display: block; } }

.b-footer__button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  line-height: 2rem;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: linear-gradient(45deg, #AD6CAD, #7336FF);
  color: #fff;
  margin-bottom: 1rem; }
  .b-footer__button:last-child {
    margin-bottom: 0; }

.b-social {
  display: flex;
  justify-content: center;
  margin: 1rem 0; }
  @media only screen and (min-width: 992px) {
    .b-social {
      margin: 0; } }
  .b-social__link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    font-size: 1.2rem;
    line-height: 1.2rem;
    color: #fff;
    width: 50px;
    height: 50px; }
    .b-social__link::after {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid #A298F1;
      z-index: -5;
      opacity: 0.1; }
    .b-social__link::before {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: linear-gradient(45deg, #AD6CAD, #7336FF);
      opacity: 0;
      transition: all 0.5s;
      z-index: -4; }
    .b-social__link:hover::before {
      opacity: 1;
      transition: all 0.5s; }
    .b-social__link:hover::after {
      border: none;
      transition: all 0.5s; }

.b-gradient__top {
  position: relative;
  min-width: 100%;
  overflow: hidden; }
  .b-gradient__top::before {
    content: ' ';
    background-image: url("../images/gradient/gradient1.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    width: 650px;
    height: 570px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -5; }
    @media only screen and (min-width: 992px) {
      .b-gradient__top::before {
        width: 900px;
        height: 788px; } }
  .b-gradient__top::after {
    content: ' ';
    background-image: url("../images/layer-header.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    width: 400px;
    height: 400px;
    display: block;
    position: absolute;
    top: 0;
    left: -10%;
    z-index: -4; }
    @media only screen and (min-width: 992px) {
      .b-gradient__top::after {
        width: 1366px;
        height: 790px; } }

.b-gradient__down {
  background: linear-gradient(0deg, #2C3485, #1D2049); }

.b-ninja {
  display: none; }

.b-language {
  position: relative;
  animation: fade 2s; }
  @media all and (max-width: 767px) {
    .b-language {
      display: none; } }
  .b-language:hover .b-language__dropdown {
    visibility: visible;
    opacity: 1; }
  .b-language__dropdown {
    visibility: hidden;
    min-width: 100%;
    transition: all .5s ease;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 100%;
    right: 0;
    margin-top: .5rem;
    z-index: 99999;
    opacity: 0;
    background-color: #1D2049;
    border: 1px solid rgba(255, 255, 255, 0.15);
    padding: .5rem 1rem;
    border-radius: 2px; }
    .b-language__dropdown::before {
      content: ' ';
      position: absolute;
      display: block;
      width: 2px;
      height: 10px;
      background-color: rgba(255, 255, 255, 0.15);
      right: 8px;
      top: -11px; }
  .b-language__link {
    color: #a298f1; }
  .b-language__link-en_active {
    color: #fff; }
  @media all and (max-width: 767px) {
    .b-language__link-en {
      margin-left: 0 !important; } }
  .b-language__link-ru_active {
    color: #fff; }
  @media all and (max-width: 767px) {
    .b-language__link-ru {
      margin-left: 0 !important; } }
  .b-language__button {
    display: none;
    color: #a298f1;
    cursor: pointer; }
  .b-language__lang-ru, .b-language__lang-en {
    display: none;
    visibility: hidden;
    opacity: 0; }
    .b-language__lang-ru_active, .b-language__lang-en_active {
      display: block;
      visibility: visible;
      opacity: 1; }
  .b-language__mobile {
    color: #fff;
    margin-left: 15%;
    font-size: 1.2rem;
    text-transform: uppercase; }

@keyframes fade {
  0% {
    opacity: 0;
    transform: translateX(100px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

.b-portfolio {
  margin-top: 4rem;
  text-align: center; }
  .b-portfolio__back {
    height: 266px;
    width: 230px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    .b-portfolio__back_default {
      background-image: url("../images/portfolio/background.svg");
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat; }
    .b-portfolio__back_buratino {
      background-image: url("../images/portfolio/background_buratino.svg");
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat; }
    .b-portfolio__back_stockus {
      background-image: url("../images/portfolio/background_stockus.svg");
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat; }
    .b-portfolio__back_deex {
      background-image: url("../images/portfolio/background_deex.svg");
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat; }
    .b-portfolio__back_synth {
      background-image: url("../images/portfolio/background_synth.svg");
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat; }
    .b-portfolio__back_eip {
      background-image: url("../images/portfolio/background_eip.svg");
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat; }
    .b-portfolio__back_casino {
      background-image: url("../images/portfolio/background_casino.svg");
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat; }
    .b-portfolio__back::before {
      content: ' ';
      background-image: url("../images/mask_one.svg");
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      width: 260px;
      height: 300px;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: all 0.5s;
      z-index: -5;
      animation: mask_one 2s infinite ease; }

@keyframes mask_one {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }
    .b-portfolio__back::after {
      content: ' ';
      background-image: url("../images/mask_two.svg");
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      width: 296px;
      height: 342px;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: all 0.5s;
      z-index: -6;
      animation: mask_one 2s infinite ease; }

@keyframes mask_one {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }
    .b-portfolio__back:hover .b-portfolio__info {
      visibility: visible;
      opacity: 1;
      transition: all 0.5s;
      transform: translateY(0); }
    .b-portfolio__back:hover .b-portfolio__logo {
      visibility: hidden;
      opacity: 0;
      transform: translateY(100px);
      transition: all 0.5s; }
  .b-portfolio__cart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 340px; }
  .b-portfolio__logo {
    position: relative;
    visibility: visible;
    opacity: 1;
    transition: 1s; }
  .b-portfolio__info {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transform: translateY(100px);
    transition: all 0.5s; }
  .b-portfolio__link {
    color: #fff; }
  .b-portfolio__desc {
    font-size: 24px;
    margin-bottom: 1rem;
    color: #fff; }
  .b-portfolio__text {
    padding: 0 1rem;
    color: #fff; }
  .b-portfolio__title {
    display: flex;
    justify-content: center;
    font-size: 20px;
    color: #A298F1;
    height: 40px;
    margin-top: 0.5rem; }
  .b-portfolio__status {
    font-size: 40px;
    color: #C0C2FF; }
  .b-portfolio__heading {
    text-align: center;
    margin-bottom: 4rem; }
  .b-portfolio__prev {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999;
    cursor: pointer; }
    .b-portfolio__prev::after {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid #A298F1;
      z-index: -5;
      opacity: 0.1; }
    .b-portfolio__prev::before {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: linear-gradient(45deg, #AD6CAD, #7336FF);
      opacity: 0;
      transition: all 0.5s;
      z-index: -4; }
    .b-portfolio__prev:hover::before {
      opacity: 1;
      transition: all 0.5s; }
    .b-portfolio__prev:hover::after {
      border: none;
      transition: all 0.5s; }
  .b-portfolio__next {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999;
    cursor: pointer; }
    .b-portfolio__next::after {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid #A298F1;
      z-index: -5;
      opacity: 0.1; }
    .b-portfolio__next::before {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: linear-gradient(45deg, #AD6CAD, #7336FF);
      opacity: 0;
      transition: all 0.5s;
      z-index: -4; }
    .b-portfolio__next:hover::before {
      opacity: 1;
      transition: all 0.5s; }
    .b-portfolio__next:hover::after {
      border: none;
      transition: all 0.5s; }
  .b-portfolio__icon_prev {
    background-image: url("../images/arrow-prev.svg");
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px; }
  .b-portfolio__icon_next {
    background-image: url("../images/arrow-next.svg");
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px; }

.b-preloader {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px; }
  .b-preloader_coin {
    margin-top: 12px; }
  .b-preloader div {
    position: absolute;
    width: 4px;
    height: 4px;
    background: #A298F1;
    border-radius: 50%;
    animation: lds-default 0.8s infinite; }
  .b-preloader div:nth-child(1) {
    animation-delay: 0s;
    top: 0;
    left: 12px; }
  .b-preloader div:nth-child(2) {
    animation-delay: 0.1s;
    top: 3px;
    left: 21px; }
  .b-preloader div:nth-child(3) {
    animation-delay: 0.2s;
    top: 12px;
    left: 24px; }
  .b-preloader div:nth-child(4) {
    animation-delay: 0.3s;
    top: 21px;
    left: 21px; }
  .b-preloader div:nth-child(5) {
    animation-delay: 0.4s;
    top: 24px;
    left: 12px; }
  .b-preloader div:nth-child(6) {
    animation-delay: 0.5s;
    top: 21px;
    left: 3px; }
  .b-preloader div:nth-child(7) {
    animation-delay: 0.6s;
    top: 12px;
    left: 0; }
  .b-preloader div:nth-child(8) {
    animation-delay: 0.7s;
    top: 3px;
    left: 3px; }

@keyframes lds-default {
  0%, 20%, 40% {
    transform: scale(1); }
  50% {
    transform: scale(1.5); } }

.b-hero-ico {
  padding-top: 40px;
  padding-bottom: 80px; }
  @media only screen and (min-width: 992px) {
    .b-hero-ico {
      padding-top: 90px;
      padding-bottom: 180px; } }
  .b-hero-ico__block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin: 40px 0;
    align-items: center; }
    @media only screen and (min-width: 992px) {
      .b-hero-ico__block {
        align-items: flex-start;
        margin: 0; } }
    .b-hero-ico__block_last {
      margin: 0; }
    .b-hero-ico__block::before {
      content: ' ';
      width: 1px;
      height: 200px;
      position: absolute;
      top: 0;
      left: -60px;
      background: #C0C2FF;
      opacity: 0.2; }
      @media all and (max-width: 1170px) {
        .b-hero-ico__block::before {
          display: none; } }
  .b-hero-ico__head {
    font-size: 20px;
    font-weight: 400; }
  .b-hero-ico__top {
    display: flex;
    align-items: center;
    margin-bottom: 1rem; }
  .b-hero-ico__icon {
    margin-right: 1rem; }
    .b-hero-ico__icon_portfel {
      background-image: url("../images/ico_page/portfel_icon.svg");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      width: 32px;
      height: 28px; }
    .b-hero-ico__icon_home {
      background-image: url("../images/ico_page/home_icon.svg");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      width: 25px;
      height: 31px; }

.b-coin-list__heading {
  color: #A298F1; }

.b-coin-list__slider {
  margin-top: 4.5rem;
  margin-bottom: 2.5rem; }
  @media all and (max-width: 1180px) {
    .b-coin-list__slider {
      margin-bottom: 1.8rem; } }
  @media all and (max-width: 640px) {
    .b-coin-list__slider {
      margin-bottom: 0.6rem; } }

.b-coin-list__cart {
  min-height: 288px;
  display: block;
  position: relative;
  border: 1px solid #A298F1;
  padding: 1rem;
  margin: 1rem;
  color: #fff;
  border-radius: 4px; }
  @media only screen and (min-width: 992px) {
    .b-coin-list__cart {
      padding: 2rem; } }
  .b-coin-list__cart:hover::before {
    opacity: 1;
    transition: all 0.5s ease; }
  .b-coin-list__cart::before {
    content: ' ';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    background: linear-gradient(45deg, #342C59, #2B2366);
    z-index: -2;
    margin-top: -1rem;
    opacity: 0;
    transition: all 0.5s ease; }
    @media only screen and (min-width: 992px) {
      .b-coin-list__cart::before {
        margin-top: -2rem; } }

.b-coin-list__top {
  display: flex;
  align-items: center;
  margin-bottom: 24px; }

.b-coin-list__footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end; }
  @media all and (max-width: 521px) {
    .b-coin-list__footer {
      flex-direction: column; } }

.b-coin-list__footer-block {
  display: flex; }

.b-coin-list__logo {
  margin-right: 0.5rem; }
  @media only screen and (min-width: 992px) {
    .b-coin-list__logo {
      margin-right: 1rem; } }
  .b-coin-list__logo svg {
    max-width: 56px;
    height: auto; }
    .b-coin-list__logo svg, .b-coin-list__logo svg polygon {
      fill: #9FA8DA; }

.b-coin-list__name {
  font-size: 32px; }
  @media only screen and (min-width: 992px) {
    .b-coin-list__name {
      font-size: 40px; } }

.b-coin-list__desc {
  font-size: 20px;
  color: #C0C2FF;
  margin-bottom: 24px; }

.b-coin-list__text {
  font-size: 16px;
  color: #6E70A4; }

.b-coin-list__number {
  line-height: 1;
  font-size: 28px;
  color: #75B82F; }
  @media only screen and (min-width: 992px) {
    .b-coin-list__number {
      font-size: 40px; } }

.b-coin-list__raised {
  margin-right: 16px; }
  @media only screen and (min-width: 992px) {
    .b-coin-list__raised {
      margin-right: 50px; } }

.b-coin-list__investors {
  margin-right: 16px; }
  @media only screen and (min-width: 992px) {
    .b-coin-list__investors {
      margin-right: 50px; } }

.b-coin-list__progress {
  margin-right: 16px; }
  @media only screen and (min-width: 992px) {
    .b-coin-list__progress {
      margin-right: 50px; } }

.b-coin-list__progress-block {
  display: flex;
  align-items: center; }

.b-coin-list__inprogress {
  font-size: 32px;
  margin-right: 1rem;
  color: #6E70A4; }
  @media only screen and (min-width: 992px) {
    .b-coin-list__inprogress {
      font-size: 40px; } }
  @media all and (max-width: 400px) {
    .b-coin-list__inprogress {
      font-size: 24px; } }

.b-coin-list__tech {
  display: flex;
  align-items: flex-start;
  margin-top: 0; }
  @media all and (max-width: 767px) {
    .b-coin-list__tech {
      margin-top: 16px; } }

.b-coin-list__tech-icon {
  margin-right: 20px; }
  .b-coin-list__tech-icon svg {
    max-width: 56px;
    height: auto; }
    .b-coin-list__tech-icon svg, .b-coin-list__tech-icon svg polygon {
      fill: #9FA8DA; }

.b-coin-list__tech-text {
  color: #6E70A4; }

.b-coin-list__prev {
  position: absolute;
  left: 0;
  bottom: -10%;
  z-index: 9999;
  cursor: pointer;
  margin-left: 2rem; }
  @media only screen and (min-width: 992px) {
    .b-coin-list__prev {
      left: 40%;
      margin-left: 0; } }
  .b-coin-list__prev::after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #A298F1;
    z-index: -5;
    opacity: 0.1; }
  .b-coin-list__prev::before {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(45deg, #AD6CAD, #7336FF);
    opacity: 0;
    transition: all 0.5s;
    z-index: -4; }
  .b-coin-list__prev:hover::before {
    opacity: 1;
    transition: all 0.5s; }
  .b-coin-list__prev:hover::after {
    border: none;
    transition: all 0.5s; }

.b-coin-list__next {
  position: absolute;
  right: 0;
  bottom: -10%;
  z-index: 9999;
  cursor: pointer;
  margin-right: 2rem; }
  @media only screen and (min-width: 992px) {
    .b-coin-list__next {
      right: 40%;
      margin-right: 0; } }
  .b-coin-list__next::after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #A298F1;
    z-index: -5;
    opacity: 0.1; }
  .b-coin-list__next::before {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(45deg, #AD6CAD, #7336FF);
    opacity: 0;
    transition: all 0.5s;
    z-index: -4; }
  .b-coin-list__next:hover::before {
    opacity: 1;
    transition: all 0.5s; }
  .b-coin-list__next:hover::after {
    border: none;
    transition: all 0.5s; }

.b-coin-list__counter {
  color: #C0C2FF;
  font-size: 20px;
  text-align: center;
  margin-bottom: 2rem; }

.b-coin-list #cp {
  opacity: 1; }

.b-coin-list #nb {
  opacity: 0.5; }

.b-coin-list #ap {
  opacity: 0.5; }

.b-invest {
  text-align: center;
  margin-bottom: 100px; }
  .b-invest__heading {
    position: relative;
    font-size: 80px;
    color: #75B82F; }
    @media only screen and (min-width: 992px) {
      .b-invest__heading {
        font-size: 120px; } }
    .b-invest__heading::before {
      content: '$';
      color: #75B82F;
      position: absolute;
      left: -10px;
      top: 17px;
      font-size: 32px; }
      @media only screen and (min-width: 992px) {
        .b-invest__heading::before {
          font-size: 40px;
          left: -20px;
          top: 30px; } }
  .b-invest__text {
    color: #A298F1;
    font-size: 20px;
    margin-bottom: 1rem; }

.b-ico-form {
  background: rgba(55, 46, 127, 0.8);
  padding: 80px 0;
  display: flex;
  align-items: center;
  position: relative; }
  .b-ico-form::before {
    content: ' ';
    background-image: url("../images/ico_page/bg_ico_form.svg");
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    top: -120px;
    transform: translateX(-50%);
    width: 1210px;
    height: 582px;
    z-index: -2; }
    @media all and (max-width: 1225px) {
      .b-ico-form::before {
        width: 100%; } }
  .b-ico-form__heading {
    text-align: center;
    color: #fff;
    font-size: 40px; }
  .b-ico-form__form {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%; }
    @media only screen and (min-width: 992px) {
      .b-ico-form__form {
        flex-direction: row;
        width: 700px; } }
  .b-ico-form__input {
    margin-right: 0;
    margin-bottom: 1rem;
    margin-top: 1rem; }
    @media only screen and (min-width: 992px) {
      .b-ico-form__input {
        margin-right: 2rem;
        margin-bottom: 0;
        margin-top: 0; } }
