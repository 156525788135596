.b-hero {

  &__content {
    position: relative;

    @media all and (max-width: 767px) {
      text-align: center;
    }
  }

  &__left {
    z-index: 2;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media all and (max-width: 767px) {
      height: auto;
    }
  }

  &__img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    z-index: -1;
    margin-left: 12rem;

    @media all and (max-width: 767px) {
      display: none;
    }

    @include flexy-breakpoint(large) {
      right: 0;
      left: auto;
      margin: 0;
    }
  }
  
  &__text {
    color: flexy-color(text);
    line-height: 2.5;
    margin-top: 1rem;
  }

  &__icon {
    display: block;
    background: {
      image: url("../images/hero-icon.svg");
      repeat: no-repeat;
      size: contain;
      position: 50% 50%;
    }
    width: 20px;
    height: 50px;
    margin-right: 1rem;
  }
  
  &__skip {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #fff;
    margin-top: 1rem;

    @media all and (max-width: 767px) {
      display: none;
    }
  }

  &__block {
    position: relative;
    display: flex;
    justify-content: center;
  }

  &__our {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 118px;
    position: relative;
    margin: 2rem 0;
    color: #fff;
    text-transform: uppercase;

    &::before {
      content: ' ';
      background: {
        image: url("../images/hero-our-icon.svg");
        position: 50% 50%;
        repeat: no-repeat;
        size: contain;
      }
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 33px;
      height: 118px;
      z-index: -2;

      animation-name: floating;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;

      @keyframes floating {
        0% {
          transform: translate(-50%, -50%);
        }
        50% {
          transform: translate(-50%, -60%);
        }
        100% {
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  
  &__our-skip {
    color: #fff;
    position: absolute;
  }

  &__br {
    display: block;

    @media all and (max-width: 767px) {
      display: none;
    }

  }

  &__mouse {
    position: relative;
    width: 20px;
    height: 30px;
    border: 2px solid #75B82F;
    border-radius: 20px;
    margin-right: 1rem;
  }

  &__mouse-wheel {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 3px;
    height: 5px;
    background: #75B82F;
    animation-name: mouse;
    animation-duration: 3s;
    animation-iteration-count: infinite;

    @keyframes mouse {
      0% {
        transform: translate(-50%, 0%);
      }
      50% {
        transform: translate(-50%, -80%);
      }
      100% {
        transform: translate(-50%, 0%);
      }
    }
  }
}
