.b-ico-form {
  background: rgba(55,46,127,0.8);
  padding: 80px 0;
  display: flex;
  align-items: center;
  position: relative;



  &::before {
    content: ' ';
    background: {
      image: url("../images/ico_page/bg_ico_form.svg");
      size: contain;
      position: 50% 50%;
      repeat: no-repeat;
    }
    position: absolute;
    left: 50%;
    top: -120px;
    transform: translateX(-50%);
    width: 1210px;
    height: 582px;
    z-index: -2;

    @media all and (max-width: 1225px) {
      width: 100%;
    }
  }

  &__heading {
    text-align: center;
    color: #fff;
    font-size: 40px;
  }

  &__form {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;

    @include flexy-breakpoint(large) {
      flex-direction: row;
      width: 700px;
    }
  }

  &__input {
    margin-right: 0;
    margin-bottom: 1rem;
    margin-top: 1rem;

    @include flexy-breakpoint(large) {
      margin-right: 2rem;
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}
