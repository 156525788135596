.b-portfolio {
  margin-top: 4rem;
  text-align: center;

  &__back {
    height: 266px;
    width: 230px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &_default {
      background: {
        image: url("../images/portfolio/background.svg");
        position: 50% 50%;
        size: cover;
        repeat: no-repeat;
      }
    }

    &_buratino {
      background: {
        image: url("../images/portfolio/background_buratino.svg");
        position: 50% 50%;
        size: cover;
        repeat: no-repeat;
      }
    }

    &_stockus {
      background: {
        image: url("../images/portfolio/background_stockus.svg");
        position: 50% 50%;
        size: cover;
        repeat: no-repeat;
      }
    }

    &_deex {
      background: {
        image: url("../images/portfolio/background_deex.svg");
        position: 50% 50%;
        size: cover;
        repeat: no-repeat;
      }
    }

    &_synth {
      background: {
        image: url("../images/portfolio/background_synth.svg");
        position: 50% 50%;
        size: cover;
        repeat: no-repeat;
      }
    }

    &_eip {
      background: {
        image: url("../images/portfolio/background_eip.svg");
        position: 50% 50%;
        size: cover;
        repeat: no-repeat;
      }
    }

    &_casino {
      background: {
        image: url("../images/portfolio/background_casino.svg");
        position: 50% 50%;
        size: cover;
        repeat: no-repeat;
      }
    }

    &::before {
      content: ' ';
      background: {
        image: url("../images/mask_one.svg");
        size: contain;
        position: 50% 50%;
        repeat: no-repeat;
      }
      width: 260px;
      height: 300px;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: all 0.5s;
      z-index: -5;
      animation: mask_one 2s infinite ease;

      @keyframes mask_one {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
    }

    &::after {
      content: ' ';
      background: {
        image: url("../images/mask_two.svg");
        size: contain;
        position: 50% 50%;
        repeat: no-repeat;
      }
      width: 296px;
      height: 342px;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: all 0.5s;
      z-index: -6;
      animation: mask_one 2s infinite ease;

      @keyframes mask_one {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
    }

    &:hover {
      .b-portfolio__info {
        visibility: visible;
        opacity: 1;
        transition: all 0.5s;
        transform: translateY(0);
      }

      .b-portfolio__logo {
        visibility: hidden;
        opacity: 0;
        transform: translateY(100px);
        transition: all 0.5s;
      }
    }

    //&:hover::before {
    //  opacity: 1;
    //  transition: all 0.5s;
    //}
    //
    //&:hover::after {
    //  opacity: 1;
    //  transition: all 1s;
    //}
  }

  &__cart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 340px;
  }

  &__logo {
    position: relative;
    visibility: visible;
    opacity: 1;
    transition: 1s;
  }

  &__info {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transform: translateY(100px);
    transition: all 0.5s;
  }

  &__link {
    color: #fff;
  }

  &__desc {
    font-size: 24px;
    margin-bottom: 1rem;
    color: #fff;
  }

  &__text {
    padding: 0 1rem;
    color: #fff;
  }

  &__title {
    display: flex;
    justify-content: center;
    font-size: 20px;
    color: #A298F1;
    height: 40px;
    margin-top: 0.5rem;
  }

  &__status {
    font-size: 40px;
    color: #C0C2FF;
  }

  &__heading {
    text-align: center;
    margin-bottom: 4rem;
  }

  //&__preload {
  //  display: block;
  //  background: {
  //    image: url("../images/spinner2.gif");
  //    position: 50% 50%;
  //    repeat: no-repeat;
  //    size: contain;
  //  }
  //  width: 30px;
  //  height: 30px;
  //}

  &__prev {
    position: absolute;
    left:0;
    top:50%;
    transform: translateY(-50%);
    z-index: 9999;
    cursor: pointer;

    &::after {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50% , -50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid #A298F1;
      z-index: -5;
      opacity: 0.1;
    }

    &::before {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50% , -50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: linear-gradient(45deg, #AD6CAD, #7336FF);
      opacity: 0;
      transition: all 0.5s;
      z-index: -4;
    }

    &:hover::before {
      opacity: 1;
      transition: all 0.5s;
    }

    &:hover::after {
      border: none;
      transition: all 0.5s;
    }
  }

  &__next {
    position: absolute;
    right:0;
    top:50%;
    transform: translateY(-50%);
    z-index: 9999;
    cursor: pointer;

    &::after {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50% , -50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid #A298F1;
      z-index: -5;
      opacity: 0.1;
    }

    &::before {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50% , -50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: linear-gradient(45deg, #AD6CAD, #7336FF);
      opacity: 0;
      transition: all 0.5s;
      z-index: -4;
    }

    &:hover::before {
      opacity: 1;
      transition: all 0.5s;
    }

    &:hover::after {
      border: none;
      transition: all 0.5s;
    }
  }

  &__icon {

    &_prev {
      background: {
        image: url("../images/arrow-prev.svg");
        position: 50% 50%;
        size: contain;
        repeat: no-repeat;
      }
      width: 20px;
      height: 20px;
    }

    &_next {
      background: {
        image: url("../images/arrow-next.svg");
        position: 50% 50%;
        size: contain;
        repeat: no-repeat;
      }
      width: 20px;
      height: 20px;
    }
  }
}
