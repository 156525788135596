@mixin svg-icon {
  svg {
    max-width: 56px;
    height: auto;

    &, polygon {
      fill: #9FA8DA;
    }
  }
}

.b-coin-list {

  &__heading {
    color: #A298F1;
  }

  &__slider {
    margin-top: 4.5rem;
    margin-bottom: 2.5rem;

    @media all and (max-width: 1180px) {
      margin-bottom: 1.8rem;
    }

    @media all and (max-width: 640px) {
      margin-bottom: 0.6rem;
    }
  }

  &__cart {
    min-height: 288px;
    display: block;
    position: relative;
    border: 1px solid #A298F1;
    padding: 1rem;
    margin: 1rem;
    color: #fff;
    border-radius: 4px;

    @include flexy-breakpoint(large) {
      padding: 2rem;
    }

    &:hover::before {
      opacity: 1;
      transition: all 0.5s ease;
    }

    &::before {
      content: ' ';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      background: linear-gradient(45deg, #342C59, #2B2366);
      z-index: -2;
      margin-top: -1rem;
      opacity: 0;
      transition: all 0.5s ease;

      @include flexy-breakpoint(large) {
        margin-top: -2rem;
      }
    }
  }

  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    @media all and (max-width: 521px) {
      flex-direction: column;
    }
  }

  &__footer-block {
    display: flex;
  }

  &__logo {
    margin-right: 0.5rem;

    @include flexy-breakpoint(large) {
      margin-right: 1rem;
    }

    @include svg-icon;
  }

  &__name {
    font-size: 32px;

    @include flexy-breakpoint(large) {
      font-size: 40px;
    }
  }

  &__desc {
    font-size: 20px;
    color: #C0C2FF;
    margin-bottom: 24px;
  }

  &__text {
    font-size: 16px;
    color: #6E70A4;
  }

  &__number {
    line-height: 1;
    font-size: 28px;
    color: #75B82F;

    @include flexy-breakpoint(large) {
      font-size: 40px;
    }
  }

  &__raised {
    margin-right: 16px;

    @include flexy-breakpoint(large) {
      margin-right: 50px;
    }
  }

  &__investors {
    margin-right: 16px;

    @include flexy-breakpoint(large) {
      margin-right: 50px;
    }
  }

  &__progress {
    margin-right: 16px;

    @include flexy-breakpoint(large) {
      margin-right: 50px;
    }
  }

  &__progress-block {
    display: flex;
    align-items: center;

  }

  &__inprogress {
    font-size: 32px;
    margin-right: 1rem;
    color: #6E70A4;

    @include flexy-breakpoint(large) {
      font-size: 40px;
    }

    @media all and (max-width: 400px) {
      font-size: 24px;
    }
  }

  &__tech {
    display: flex;
    align-items: flex-start;
    margin-top: 0;

    @media all and (max-width: 767px){
      margin-top: 16px;
    }
  }

  &__tech-icon {
    margin-right: 20px;

    @include svg-icon;
  }

  &__tech-text {
    color: #6E70A4;
  }

  &__prev {
    position: absolute;
    left: 0;
    bottom: -10%;
    z-index: 9999;
    cursor: pointer;
    margin-left: 2rem;

    @include flexy-breakpoint(large) {
      left: 40%;
      margin-left: 0;
    }

    &::after {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50% , -50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid #A298F1;
      z-index: -5;
      opacity: 0.1;
    }

    &::before {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50% , -50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: linear-gradient(45deg, #AD6CAD, #7336FF);
      opacity: 0;
      transition: all 0.5s;
      z-index: -4;
    }

    &:hover::before {
      opacity: 1;
      transition: all 0.5s;
    }

    &:hover::after {
      border: none;
      transition: all 0.5s;
    }
  }

  &__next {
    position: absolute;
    right: 0;
    bottom: -10%;
    z-index: 9999;
    cursor: pointer;
    margin-right: 2rem;

    @include flexy-breakpoint(large) {
      right: 40%;
      margin-right: 0;
    }

    &::after {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50% , -50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid #A298F1;
      z-index: -5;
      opacity: 0.1;
    }

    &::before {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50% , -50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: linear-gradient(45deg, #AD6CAD, #7336FF);
      opacity: 0;
      transition: all 0.5s;
      z-index: -4;
    }

    &:hover::before {
      opacity: 1;
      transition: all 0.5s;
    }

    &:hover::after {
      border: none;
      transition: all 0.5s;
    }
  }

  &__counter {
    color: #C0C2FF;
    font-size: 20px;
    text-align: center;
    margin-bottom: 2rem;
  }

  #cp {
    opacity: 1;
  }

  #nb {
    opacity: 0.5;
  }

  #ap {
    opacity: 0.5;
  }
}
