.b-language {
  position: relative;
  animation: fade 2s;

  @media all and (max-width: 767px) {
    display: none;
  }

  &:hover {
    .b-language__dropdown {
      visibility: visible;
      opacity: 1;
    }
  }

  &__dropdown {
    visibility: hidden;
    min-width: 100%;
    transition: all .5s ease;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 100%;
    right: 0;
    margin-top: .5rem;
    z-index: 99999;
    opacity: 0;
    background: {
      color: #1D2049;
    }
    border: 1px solid rgba(#fff, .15);
    padding: .5rem 1rem;
    border-radius: 2px;

    &::before {
      content: ' ';
      position: absolute;
      display: block;
      width: 2px;
      height: 10px;
      background-color: rgba(#fff, .15);
      right: 8px;
      top: -11px;
    }
  }

  &__link {
    color: #a298f1;
  }

  &__link-en {
    &_active {
      color: #fff;
    }
    @media all and (max-width: 767px) {
      margin-left: 0!important;
    }
  }

  &__link-ru {
    &_active {
      color: #fff;
    }
    @media all and (max-width: 767px) {
      margin-left: 0!important;
    }
  }

  &__button {
    display: none;
    color: #a298f1;
    cursor: pointer;
  }

  &__lang-ru,
  &__lang-en {
    display: none;
    visibility: hidden;
    opacity: 0;

    &_active {
      display: block;
      visibility: visible;
      opacity: 1;
    }
  }

  &__mobile {
    color: #fff;
    margin-left: 15%;
    font-size: 1.2rem;
    text-transform: uppercase;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
