.b-header {

  &__content {
    position: relative;
    padding: 2rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__mobile {
    display: none;

    @media all and (max-width: 767px) {
      display: block;
    }
  }

  &__block {
    display: flex;
  }
}
