.b-heading {
  font-size: 40px;
  color: #fff;
  line-height: 1.5;
  text-align: left;

  @media all and (max-width: 767px) {
    text-align: center;
  }

  &_hero {
    &::before {
      content: ' ';
      width: 50px;
      height: 2px;
      background-color: #75B82F;
      position: relative;
      top: 0;
      left: 0;
      display: block;
      margin-bottom: 1rem;

      @media all and (max-width:767px)  {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &_blue {
    &::before {
      content: ' ';
      width: 50px;
      height: 2px;
      background-color: #4488FF;
      position: relative;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      margin-bottom: 1rem;
    }
  }

  &_green {
    text-align: center;
    &::before {
      content: ' ';
      width: 50px;
      height: 2px;
      background-color: #75B82F;
      position: relative;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      margin-bottom: 1rem;
    }
  }


}