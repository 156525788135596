.b-spinner {
  background-color: flexy-color();
  display: block;
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  border-radius: 100%;
  opacity: 0;
  animation: animation-scaleout 0.9s infinite ease-in-out;
}

@keyframes animation-scaleout {
  0% {
    opacity: 0.3;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

