.b-social {
  display: flex;
  justify-content: center;
  margin: 1rem 0;

  @include flexy-breakpoint(large) {
    margin: 0;
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    font-size: 1.2rem;
    line-height: 1.2rem;
    color: #fff;
    width: 50px;
    height: 50px;

    &::after {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50% , -50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid #A298F1;
      z-index: -5;
      opacity: 0.1;
    }

    &::before {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50% , -50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: linear-gradient(45deg, #AD6CAD, #7336FF);
      opacity: 0;
      transition: all 0.5s;
      z-index: -4;
    }

    &:hover::before {
      opacity: 1;
      transition: all 0.5s;
    }

    &:hover::after {
      border: none;
      transition: all 0.5s;
    }
  }
}
