.b-tech {

  &__block {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
  }

  &__heading {
    text-align: center;
  }
}
