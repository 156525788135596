.b-contacts {
  margin-top: 2rem;

  &__form {
    width: 100%;
    margin: 0 auto;

    @include flexy-breakpoint(large) {
      width: 750px;
    }
  }

  &__form-area {
    border-bottom: 1px solid #a298f1;
    width: 100%;
    height: 56px;
    color: #fff;
    margin-top: 2rem;
    margin-bottom: 2rem;

    @include flexy-breakpoint(large) {
      width: 750px;
    }

    &:focus,
    &:active {
      border-bottom: 1px solid #fff;
    }

    &::placeholder {
      color: #a298f1;
    }
  }

  &__row {
    display: block;

    @include flexy-breakpoint(large) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1rem;
    }
  }
}
