.b-menu-mobile {
  display: none;

  @media all and (max-width: 767px) {
    display: block;
  }

  &__button-block {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -10px;
  }

  &__button {
    position: relative;

    span {
      display: block;
      width: 20px;
      height: 2px;
      background: #fff;

      &::before {
        content: ' ';
        position: absolute;
        top: 6px;
        display: block;
        width: 20px;
        height: 2px;
        background: #fff;
      }

      &::after {
        content: ' ';
        position: absolute;
        top: -6px;
        display: block;
        width: 20px;
        height: 2px;
        background: #fff;
      }
    }

  }

  &__bg {
    background: {
      color: rgba(#000, 0.5);
    }
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    cursor: pointer;
    transition: opacity 0.2s ease;

    &_open {
      left: 0;
      visibility: visible;
      opacity: 1;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    left: -100%;
    width: calc(100% - 30%);
    height: 100vh;
    top: 0;
    background: linear-gradient(#AD6CAD, #7336FF);
    box-sizing: border-box;
    z-index: 101;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;

    &_open {
      left: 0;
      visibility: visible;
      opacity: 1;
      margin-left: -1rem;
    }
  }


  &__link {
    color: #fff;
    margin-bottom: 1rem;
    margin-left: 15%;
    font-size: 1.2rem;
    text-transform: uppercase;
  }

  &__home {
    color: #fff;
    margin-bottom: 1rem;
    margin-left: 15%;
    font-size: 1.2rem;
    text-transform: uppercase;
  }

}
