.b-preloader {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;

  &_coin {
    margin-top: 12px;
  }

  div {
    position: absolute;
    width: 4px;
    height: 4px;
    background: #A298F1;
    border-radius: 50%;
    animation: lds-default 0.8s infinite;
  }

  div:nth-child(1) {
    animation-delay: 0s;
    top: 0;
    left: 12px;
  }

  div:nth-child(2) {
    animation-delay: 0.1s;
    top: 3px;
    left: 21px;
  }

  div:nth-child(3) {
    animation-delay: 0.2s;
    top: 12px;
    left: 24px;
  }

  div:nth-child(4) {
    animation-delay: 0.3s;
    top: 21px;
    left: 21px;
  }

  div:nth-child(5) {
    animation-delay: 0.4s;
    top: 24px;
    left: 12px;
  }

  div:nth-child(6) {
    animation-delay: 0.5s;
    top: 21px;
    left: 3px;
  }

  div:nth-child(7) {
    animation-delay: 0.6s;
    top: 12px;
    left: 0;
  }

  div:nth-child(8) {
    animation-delay: 0.7s;
    top: 3px;
    left: 3px;
  }
}
@keyframes lds-default {
  0%, 20%, 40% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
